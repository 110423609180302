import { useLocation, Link } from 'react-router-dom';
import { Collapse, Select, Card, Drawer, Button, Table, Tooltip, Pagination, Tag, DatePicker, Progress, Statistic, Col, Row, Popover, Modal } from 'antd';
import { useState, useEffect, useRef } from 'react';
import SimpleReactValidator from "simple-react-validator";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ExclamationCircleTwoTone, NotificationTwoTone, FullscreenOutlined, UpOutlined, ExportOutlined, EyeOutlined, FilterOutlined } from '@ant-design/icons';
import { getdata, tabledata } from '../../../Store/Action/Report/NotificationReport';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';

const mapStateToProps = (state) => ({
    reportlist: state.NotificationReport.notificationreport
})
const NotificationReport = ({ getdata, tabledata, reportlist }) => {
    const { Panel } = Collapse;
    const { Option } = Select;
    const location = useLocation();
    const paramid = location.pathname;
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const validator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [key, setKey] = useState("1")
    const [statkey, setStatKey] = useState("0")
    let [index, setindex] = useState(0)
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [pagecount, setPagecount] = useState(false)
    const [tableshow, setTableShow] = useState(false)
    const [titledata, Settitledata] = useState(undefined)
    const [countrydata, setCountryData] = useState(null)
    const [filterdata, setFilterData] = useState({
        domain: [],
        act: []
    })
    const [disableButton, setDisableButton] = useState(false)

    const [notificationreport, setNotificationReport] = useState({
        country: "",
        legel_entity: "",
        domain: "",
        act: "",
        from_date: "",
        to_date: "",
        domain_id: null,
        parent_id: null,
        countryName: '',
    })
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [paginationArray, setPaginationArray] = useState([])
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    const onshow = () => {
        setExitCollapse(true);
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotificationsListReportData",
                        {
                            "country_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? countrydata && countrydata[0] && countrydata[0].c_id : sessionArr[0].c_id,
                            "legal_entity_id": entityid,
                            "domain_id": Number(notificationreport.domain_id),
                            "parent_id": notificationreport.parent_id ? Number(notificationreport.parent_id) : 0,
                            "due_from_date": notificationreport.from_date != "" ? moment(notificationreport.from_date).format('DD-MMM-YYYY') : null,
                            "due_to_date": notificationreport.to_date != "" ? moment(notificationreport.to_date._d).format('DD-MMM-YYYY') : null,
                            "csv": false,
                            "from_count": 0,
                            "page_count": 25
                        }
                    ]
                }
            ]
            tabledata({
                payload: payload,
                paramid: paramid
            })
        }
    }
    const showmore = ((value) => {
        setTableShow(true)
        setKey(0)
        setStatKey(1)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetStatutoryNotificationsListReportData",
                    {
                        "country_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? countrydata && countrydata[0] && countrydata[0].c_id : sessionArr[0].c_id,
                        "legal_entity_id": entityid,
                        "domain_id": Number(notificationreport.domain_id),
                        "parent_id": notificationreport.parent_id ? Number(notificationreport.parent_id) : 0,
                        "due_from_date": notificationreport.from_date != "" ? moment(notificationreport.from_date).format('DD-MMM-YYYY') : null,
                        "due_to_date": notificationreport.to_date != "" ? moment(notificationreport.to_date._d).format('DD-MMM-YYYY') : null,
                        "csv": false,
                        "from_count": 0,
                        "page_count": Number(value)
                    }
                ]
            }
        ]
        tabledata({
            payload: payload,
            paramid: paramid
        })
    })
    useEffect(() => {
        validator.current.showMessages()
    }, [])


    useEffect(() => {
        if (reportlist && reportlist.tabledata && reportlist.tabledata.length > 0) {
            var array = reportlist.tabledata.slice((current - 1) * pageSize, (current * pageSize))
            setPaginationArray(array)
        }
    }, [reportlist.tabledata, pageSize, current])

    useEffect(() => {
        if (pageState === true) {
            setindex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])


    useEffect(() => {
        if (isAuth && entityid !== "null" && entityid !== '') {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotificationsListReportFilters",
                        {
                            "country_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? countrydata && countrydata[0] && countrydata[0].c_id : sessionArr[0].c_id,
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            getdata({
                payload: payload,
                paramid: paramid
            })
        }
    }, [authtoken, isAuth, entityid, countrydata])


    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            const country = sessionParsedValue.country_info
            console.log(country, "country7777");
            setCountryData(country)
        }
    }, [localStorage.getItem('SelectedEntityid')])


    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') !== "All Legal Entity") {
            setNotificationReport({
                ...notificationreport,
                country: sessionArr && sessionArr.length && sessionArr[0].c_id,
                legel_entity: sessionArr[0].le_name
            })
        }
    }, [localStorage.getItem('SelectedEntity') !== "All Legal Entity"])

    console.log(notificationreport, 'notificationreport');

    useEffect(() => {
        if (reportlist.getdata) {
            let actdata = _.filter(reportlist && reportlist.getdata && reportlist.getdata.act_legal_entity, { domain_id: Number(notificationreport.domain_id) })
            console.log(actdata, "actdata");
            setFilterData({
                ...filterdata,
                domain: reportlist && reportlist.getdata && reportlist.getdata.domains,
                act: actdata
            })
        }
    }, [reportlist.getdata, notificationreport.domain_id])
    useEffect(() => {
        if (reportlist.tabledata) {
            Settitledata(reportlist && reportlist.tabledata && reportlist.tabledata.stat_notf_list_report)
        }
    }, [reportlist.tabledata])


    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    const onChange = (key) => {
        console.log(key);
    };

    const onStatChange = (key) => {
        console.log(key);
    }

    const disablecondition = (e) => {
        console.log(e, 'eeeeee');
        var currentToDate = moment(notificationreport.from_date).add(3, 'months')
        console.log(currentToDate, 'currentToDate')
        if (currentToDate < moment(e)) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }

    const content = (
        <Row gutter={16}>
            <Col span={24}>
                <Statistic title="Notification Received" value={1127} prefix={<NotificationTwoTone />} />
            </Col>
        </Row>
    );

    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    return (
        <>
            <div id='page-wrapper' className="page-wrapper">
                <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                <div className="page-titles pb-0 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Statutory Setting Reports</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Notification Report</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-4 text-end">
                            <Button
                                className="mt-1"
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button>
                            <button style={{ float: 'right', marginTop: '5px' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title="Full Screen Mode"><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" id='only-restricted' style={{ position: 'relative', padding: "10px 3px" }}>
                                <div className={localStorage.getItem('currentTheme')}>
                                    <Collapse accordion defaultActiveKey={["1"]} onChange={onChange} className='report-collapse' style={{ display: exitCollapse ? "none" : "block" }}>
                                        <Panel header="Notification Report" key={key}>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label><b>Country: </b> <span style={{ color: "red" }}>*</span></label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <Select
                                                                allowClear={true}
                                                                placeholder='Enter Country'
                                                                onChange={(value, data) => {
                                                                    if (value !== undefined) {
                                                                        setNotificationReport({
                                                                            ...notificationreport,
                                                                            country: value,
                                                                            countryName: data.children,
                                                                            legel_entity: "",
                                                                            domain: "",
                                                                            act: "",
                                                                            from_date: "",
                                                                            to_date: "",
                                                                            domain_id: null,
                                                                            parent_id: null
                                                                        })
                                                                    }
                                                                    else {
                                                                        setNotificationReport({
                                                                            ...notificationreport,
                                                                            country: '',
                                                                            countryName: '',
                                                                            legel_entity: "",
                                                                            domain: "",
                                                                            act: "",
                                                                            from_date: "",
                                                                            to_date: "",
                                                                            domain_id: null,
                                                                            parent_id: null
                                                                        })
                                                                    }
                                                                }}
                                                                style={{ width: '100%', marginTop: '3px' }}
                                                                value={notificationreport.countryName || undefined}

                                                            >
                                                                {countrydata && countrydata.length > 0 && countrydata.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            : <p>{sessionArr[0].c_name}</p>}
                                                        {validator.current.message(
                                                            'countryName',
                                                            notificationreport.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required ',
                                                                }
                                                            })}

                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b> Legal Entity: </b><span style={{ color: "red" }}>*</span></label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                            <Select
                                                                allowClear={true}
                                                                disabled={notificationreport.country == '' ? true : false}
                                                                placeholder='Enter Legal Entity'
                                                                onChange={(value, data) => {
                                                                    if (value !== undefined) {
                                                                        setCurrentEntity(value)
                                                                        setNotificationReport({
                                                                            ...notificationreport,
                                                                            legel_entity: data.children,
                                                                            domain: "",
                                                                            act: "",
                                                                            from_date: "",
                                                                            to_date: "",
                                                                            domain_id: null,
                                                                            parent_id: null
                                                                        })
                                                                    }
                                                                    else {
                                                                        setCurrentEntity('')
                                                                        setNotificationReport({
                                                                            ...notificationreport,
                                                                            legel_entity: '',
                                                                            domain: "",
                                                                            act: "",
                                                                            from_date: "",
                                                                            to_date: "",
                                                                            domain_id: null,
                                                                            parent_id: null
                                                                        })
                                                                    }
                                                                }}
                                                                style={{ width: '100%', marginTop: '3px' }}
                                                                value={notificationreport.legel_entity || undefined}


                                                            >
                                                                {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}                                                        </Select>
                                                            : <p>{localStorage.getItem('SelectedEntity')}</p>}


                                                        {validator.current.message(
                                                            'legel_entity',
                                                            notificationreport.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Domain: </b> <span style={{ color: "red" }}>*</span></label>
                                                        <Select
                                                            allowClear={true}
                                                            onChange={(value, data) => {
                                                                if (value !== undefined) {
                                                                    setNotificationReport({
                                                                        ...notificationreport,
                                                                        domain_id: value,
                                                                        domain: data.children,
                                                                        act: "",
                                                                        from_date: "",
                                                                        to_date: "",
                                                                        parent_id: null
                                                                    })

                                                                }
                                                                else {
                                                                    setNotificationReport({
                                                                        ...notificationreport,
                                                                        domain_id: '',
                                                                        domain: '',
                                                                        act: "",
                                                                        from_date: "",
                                                                        to_date: "",
                                                                        parent_id: null
                                                                    })
                                                                }
                                                            }}
                                                            placeholder="Enter Domain Name"
                                                            style={{ width: '100%', marginTop: '5px' }}

                                                            value={notificationreport.domain || undefined}
                                                        >
                                                            {filterdata.domain && filterdata.domain.length > 0 && filterdata.domain.map((item, i) => {
                                                                return (
                                                                    // data.unit ?
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                    // :""
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'domain',
                                                            notificationreport.domain,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Domain Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "5px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label><b>Act : </b></label>
                                                        <Select
                                                            allowClear={true}
                                                            placeholder='Enter Act'
                                                            onChange={(value, data) => {
                                                                if (value !== undefined) {
                                                                    setNotificationReport({
                                                                        ...notificationreport,
                                                                        act: data.children,
                                                                        parent_id: value
                                                                    })
                                                                }
                                                                else {
                                                                    setNotificationReport({
                                                                        ...notificationreport,
                                                                        act: '',
                                                                        parent_id: ''
                                                                    })
                                                                }
                                                            }}
                                                            style={{ width: '100%', marginTop: '3px' }}

                                                            value={notificationreport.act || undefined}
                                                        >
                                                            {filterdata.act && filterdata.act.length > 0 && filterdata.act.map((item, i) => {
                                                                return (
                                                                    <Option key={item.parent_id}>
                                                                        {item.statutory_mapping}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>From Date: </b></label>
                                                        <DatePicker allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '3px' }}
                                                            value={notificationreport.from_date ? moment(notificationreport.from_date) : ''}

                                                            disabled={notificationreport.domain == '' ? true : false}
                                                            onChange={(date, dateString) => {
                                                                let tempDate = moment(dateString).add(3, 'months')
                                                                let newDate = moment(tempDate).subtract(1, 'day')
                                                                let correctDate = moment(newDate._d).format('DD-MMM-YYYY')


                                                                console.log(moment(dateString).add(3, 'months'), 'dateString');
                                                                setNotificationReport({
                                                                    ...notificationreport,
                                                                    from_date: dateString,
                                                                    to_date: correctDate
                                                                })

                                                            }}

                                                        />

                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>To Date: </b></label><br />
                                                        <DatePicker allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '3px' }}

                                                            onChange={(date, dateString) => {
                                                                console.log(dateString, "dateString");
                                                                disablecondition(dateString)
                                                                setNotificationReport({
                                                                    ...notificationreport,
                                                                    to_date: dateString._d
                                                                })
                                                            }}
                                                            disabledDate={(current) => {
                                                                return moment(notificationreport.from_date).add(1, 'days') >= current ||
                                                                    moment(notificationreport.from_date).add(1, 'year') < current;
                                                            }}
                                                            value={notificationreport.to_date ? moment(notificationreport.to_date) : ''}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "1%" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 popupbtncolour text-center'>
                                                        <Button disabled={disableButton} type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                            onClick={onshow} icon={<EyeOutlined />}
                                                        >
                                                            Show
                                                        </Button>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <Modal
                                        onCancel={setcancelFilter}
                                        footer={false}
                                        title="Unit List"
                                        className={
                                            "add-service-prv cons-report " +
                                            localStorage.getItem("currentTheme")
                                        }
                                        visible={filterModalVisible}
                                    >
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Country: </b> <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <Select
                                                            allowClear={true}
                                                            placeholder='Enter Country'
                                                            onChange={(value, data) => {
                                                                if (value !== undefined) {
                                                                    setNotificationReport({
                                                                        ...notificationreport,
                                                                        country: value,
                                                                        countryName: data.children,
                                                                        legel_entity: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        to_date: "",
                                                                        domain_id: null,
                                                                        parent_id: null
                                                                    })
                                                                }
                                                                else {
                                                                    setNotificationReport({
                                                                        ...notificationreport,
                                                                        country: '',
                                                                        countryName: '',
                                                                        legel_entity: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        to_date: "",
                                                                        domain_id: null,
                                                                        parent_id: null
                                                                    })
                                                                }
                                                            }}
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            value={notificationreport.countryName || undefined}

                                                        >
                                                            {countrydata && countrydata.length > 0 && countrydata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        : <p>{sessionArr[0].c_name}</p>}
                                                    {validator.current.message(
                                                        'countryName',
                                                        notificationreport.country,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Country Required ',
                                                            }
                                                        })}

                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b> Legal Entity: </b><span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                        <Select
                                                            allowClear={true}
                                                            disabled={notificationreport.country == '' ? true : false}
                                                            placeholder='Enter Legal Entity'
                                                            onChange={(value, data) => {
                                                                if (value !== undefined) {
                                                                    setCurrentEntity(value)
                                                                    setNotificationReport({
                                                                        ...notificationreport,
                                                                        legel_entity: data.children,
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        to_date: "",
                                                                        domain_id: null,
                                                                        parent_id: null
                                                                    })
                                                                }
                                                                else {
                                                                    setCurrentEntity('')
                                                                    setNotificationReport({
                                                                        ...notificationreport,
                                                                        legel_entity: '',
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        to_date: "",
                                                                        domain_id: null,
                                                                        parent_id: null
                                                                    })
                                                                }
                                                            }}
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            value={notificationreport.legel_entity || undefined}


                                                        >
                                                            {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}                                                           </Select>
                                                        : <p>{localStorage.getItem('SelectedEntity')}</p>}


                                                    {validator.current.message(
                                                        'legel_entity',
                                                        notificationreport.legel_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain: </b> <span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        allowClear={true}
                                                        onChange={(value, data) => {
                                                            if (value !== undefined) {
                                                                setNotificationReport({
                                                                    ...notificationreport,
                                                                    domain_id: value,
                                                                    domain: data.children,
                                                                    act: "",
                                                                    from_date: "",
                                                                    to_date: "",
                                                                    parent_id: null
                                                                })

                                                            }
                                                            else {
                                                                setNotificationReport({
                                                                    ...notificationreport,
                                                                    domain_id: '',
                                                                    domain: '',
                                                                    act: "",
                                                                    from_date: "",
                                                                    to_date: "",
                                                                    parent_id: null
                                                                })
                                                            }
                                                        }}
                                                        placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={notificationreport.domain || undefined}
                                                    >
                                                        {filterdata.domain && filterdata.domain.length > 0 && filterdata.domain.map((item, i) => {
                                                            return (
                                                                // data.unit ?
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                                // :""
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'domain',
                                                        notificationreport.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Act : </b></label>
                                                    <Select
                                                        allowClear={true}
                                                        placeholder='Enter Act'
                                                        onChange={(value, data) => {
                                                            if (value !== undefined) {
                                                                setNotificationReport({
                                                                    ...notificationreport,
                                                                    act: data.children,
                                                                    parent_id: value
                                                                })
                                                            }
                                                            else {
                                                                setNotificationReport({
                                                                    ...notificationreport,
                                                                    act: '',
                                                                    parent_id: ''
                                                                })
                                                            }
                                                        }}
                                                        style={{ width: '100%', marginTop: '3px' }}

                                value={notificationreport.act || undefined}
                            >
                                {filterdata.act && filterdata.act.length > 0 && filterdata.act.map((item, i) => {
                                    return (
                                        <Option key={item.parent_id}>
                                            {item.statutory_mapping}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className='col-md-4'>
                            <label><b>From Date: </b></label>
                            <DatePicker allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '3px' }}
                                value={notificationreport.from_date ? moment(notificationreport.from_date) : ''}

                                                        disabled={notificationreport.domain == '' ? true : false}
                                                        onChange={(date, dateString) => {
                                                            let tempDate = moment(dateString).add(3, 'months')
                                                            let newDate = moment(tempDate).subtract(1, 'day')
                                                            let correctDate = moment(newDate._d).format('DD-MMM-YYYY')


                                                            console.log(moment(dateString).add(3, 'months'), 'dateString');
                                                            setNotificationReport({
                                                                ...notificationreport,
                                                                from_date: dateString,
                                                                to_date: correctDate
                                                            })

                                                        }}

                                                    />

                        </div>
                        <div className='col-md-4'>
                            <label><b>To Date: </b></label><br />
                            <DatePicker allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '3px' }}

                                                        onChange={(date, dateString) => {
                                                            console.log(dateString, "dateString");
                                                            disablecondition(dateString)
                                                            setNotificationReport({
                                                                ...notificationreport,
                                                                to_date: dateString._d
                                                            })
                                                        }}
                                                        disabledDate={(current) => {
                                                            return moment(notificationreport.from_date).add(1, 'days') >= current ||
                                                                moment(notificationreport.from_date).add(1, 'year') < current;
                                                        }}
                                                        value={notificationreport.to_date ? moment(notificationreport.to_date) : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "1%" }}>
                                            <div className='row'>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-4 popupbtncolour text-center'>
                                                    <Button disabled={disableButton} type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                        onClick={onshow} icon={<EyeOutlined />}
                                                    >
                                                        Show
                                                    </Button>
                                                </div>
                                                <div className='col-md-4'></div>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                                {tableshow == true ?
                                    <Card className='rounded-3'>
                                        <div className='col-md-12 mt-1 mb-1' >
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity : </b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? notificationreport.legel_entity : localStorage.getItem('SelectedEntity')}</label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Country : </b>{sessionArr && sessionArr[0].c_name}</label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain : </b>{notificationreport.domain}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={localStorage.getItem('currentTheme')}>
                                            <div className='ant-table ant-table-bordered ant-table-scroll-horizontal'>
                                                <div className='ant-table-wrapper'>
                                                    <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content audittrailtable'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className="ant-table-thead antable-head">
                                                                        <tr>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '10px' }} > # </th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}><center>Act</center></th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '250px' }}><center>Compliance Task</center></th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '70px' }}><center>Date</center></th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '230px' }}><center>Notification Content</center></th>
                                                                        </tr>
                                                                    </thead>

                                                                    {/* <> */}
                                                                    {/* <tbody> */}
                                                                    {titledata && titledata.length > 0 ?
                                                                        titledata.map((itemdata, i) => {
                                                                            let temparray = []
                                                                            return <>
                                                                                <tbody className='ant-table-tbody'>
                                                                                    <tr>
                                                                                        <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                        <td className='ant-table-cell'>{itemdata.statutory_mapping} </td>
                                                                                        <td className='ant-table-cell'>{itemdata.compliance_task}  <Tooltip title={itemdata.compliance_description}><ExclamationCircleTwoTone /></Tooltip> &nbsp;</td>
                                                                                        <td className='ant-table-cell'><center>{itemdata.created_on}</center></td>
                                                                                        <td className='ant-table-cell'>{itemdata.notification_text}</td>
                                                                                        <span hidden>{index = index + 1}</span>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </>
                                                                        }) :
                                                                        <tr>
                                                                            <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                        </tr>
                                                                    }
                                                                    {/* </tbody> */}
                                                                    {/* </> */}
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            {index != 0 ?
                                                <>
                                                    <div className='col-md-6'>
                                                        <label>Showing {pageSize * (current - 1) + 1} to {index} of {reportlist && reportlist.tabledata && reportlist.tabledata.total_count} entries</label>
                                                    </div>
                                                    <div className='col-md-6 text-right'>
                                                        <Pagination
                                                            current={current}
                                                            showSizeChanger={true}
                                                            onShowSizeChange={() => {
                                                                setCurrent(1)
                                                            }}
                                                            pageSizeOptions={[25, 50, 100]}
                                                            hideOnSinglePage={reportlist.tabledata.total_count > 25 ? false : true} defaultPageSize={25}
                                                            onChange={(page, pageSizes) => {
                                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                                setPageSize(pageSizes)
                                                            }} total={reportlist.tabledata.total_count}

                                                        />
                                                    </div> </> : ''}
                                        </div>
                                    </Card>

                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default connect(mapStateToProps, {
    getdata,
    tabledata
})(NotificationReport);