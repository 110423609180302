import { useLocation, Link } from 'react-router-dom';
import { Collapse, Select, Card, Drawer, Button, Table, Tooltip, Pagination, Modal, Progress } from 'antd';
import { useState, useEffect, useRef } from 'react';
import SimpleReactValidator from "simple-react-validator";
import { connect } from 'react-redux';
import { getdata, tabledata } from './../../../Store/Action/Report/Serviceproviderdetails'
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import { ExclamationCircleTwoTone, InfoCircleOutlined, EyeOutlined, ExportOutlined, ArrowRightOutlined, ArrowLeftOutlined, PlayCircleOutlined, DownloadOutlined, UpOutlined, FullscreenOutlined, FilterOutlined } from '@ant-design/icons';
import ScrollToTop from "react-scroll-to-top";


const mapStateToProps = (state) => ({
    reportlist: state.ServiceProviderDetails.serviceproviderdetails
})

const ServiceProviderDetails = ({ getdata, reportlist, tabledata }) => {
    const { Panel } = Collapse;
    const { Option } = Select;
    const location = useLocation();
    const paramid = location.pathname;
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const validator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tablelist, setTableList] = useState([])
    const [childdata, setChilddata] = useState([])
    const [statkey, setStatKey] = useState("0")
    const [key, setKey] = useState("1")
    const [tableshow, setTableShow] = useState(false)
    let [index, setindex] = useState(0)
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [pagecount, setPagecount] = useState(false)
    const [childState, setChildState] = useState(false)
    const [titledata, Settitledata] = useState(undefined)
    const [paginationArray, setPaginationArray] = useState([])
    const [pageSize, setPageSize] = useState(25)
    const [current, setCurrent] = useState(1)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [serviceproviderdetails, setServiceProviderDetails] = useState({
        service_provider: "",
        sp_id: null,
        user: "",
        user_id: null,
        status: ""
    })
    const [pageState, setpageState] = useState(false);
    const [datalist, setDataList] = useState({
        service_provider: [],
        user: [],
        status: []
    })
    const handleCancel = () => {
        setIsModalVisible(false)
    };
    const onshow = () => {
        setfilteredArrayState([])
        Settitledata([])
        setExitCollapse(true);
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
        setTableShow(true)
        setKey(0)
        setStatKey(1)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetServiceProviderDetailsReport",
                    {
                        "sp_id": serviceproviderdetails.sp_id ? Number(serviceproviderdetails.sp_id) : 0,
                        "user_id": serviceproviderdetails.user_id ? Number(serviceproviderdetails.user_id) : 0,
                        "s_p_status": serviceproviderdetails.status ? serviceproviderdetails.status : "All",
                        "from_count": 0,
                        "page_count": 25
                    }
                ]
            }
        ]
        tabledata({
            payload: payload,
            paramid: paramid
        })
    }


    const showmore = ((value) => {
        setTableShow(true)
        setKey(0)
        setStatKey(1)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetServiceProviderDetailsReport",
                    {
                        "sp_id": serviceproviderdetails.sp_id ? Number(serviceproviderdetails.sp_id) : 0,
                        "user_id": serviceproviderdetails.user_id ? Number(serviceproviderdetails.user_id) : 0,
                        "s_p_status": serviceproviderdetails.status ? serviceproviderdetails.status : "All",
                        "from_count": 0,
                        "page_count": Number(value)
                    }
                ]
            }
        ]
        tabledata({
            payload: payload,
            paramid: paramid
        })

    })
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetServiceProviderDetailsReportFilters",
                        {}
                    ]
                }
            ]
            getdata({
                payload: payload,
                paramid: paramid
            })
        }
    }, [authtoken, isAuth])
    useEffect(() => {
        validator.current.showMessages()
    }, [])
    useEffect(() => {
        if (reportlist && reportlist.getdata) {
            let userdata = _.filter(reportlist && reportlist.getdata && reportlist.getdata.sp_user_list, { sp_id_optional: Number(serviceproviderdetails.sp_id) })
            setDataList({
                ...datalist,
                service_provider: reportlist && reportlist.getdata && reportlist.getdata.sp_list,
                user: userdata,
                status: reportlist && reportlist.getdata && reportlist.getdata.sp_status_list
            })
        }
    }, [reportlist && reportlist.getdata, serviceproviderdetails])
    useEffect(() => {
        if (reportlist && reportlist.tabledata) {
            setTableList(reportlist && reportlist.tabledata)
        }
    }, [reportlist && reportlist.tabledata])
    useEffect(() => {
        if (reportlist && reportlist.tabledata) {
            var array = reportlist && reportlist.tabledata && reportlist && reportlist.tabledata.sp_details_list && reportlist.tabledata.sp_details_list.length > 0 && reportlist.tabledata.sp_details_list.slice((current - 1) * pageSize, (current * pageSize))
            setPaginationArray(array)
        }
    }, [reportlist && reportlist.tabledata, pageSize, current])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (paginationArray) {
            const ab = paginationArray && paginationArray.length > 0 && paginationArray.map(function (item) {
                if ((array.indexOf(item.sp_id) < 0))
                    array.push(item.sp_id)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let temp1 = [];
                let entityArray1 = [];
                let datavalue = [];
                if (data) {
                    paginationArray && paginationArray.length > 0 && paginationArray.map((child, childIndex) => {
                        if (data === child.sp_id && child.contract_period === null) {
                            entityArray = {
                                "address": child.address,
                                "con_no": child.con_no,
                                "contract_period": child.contract_period,
                                "email_id": child.email_id,
                                "s_p_status": child.s_p_status,
                                "sp_id": child.sp_id,
                                "sp_name": child.sp_name,
                                "sp_status_date": child.sp_status_date,
                                "unit_count": child.unit_count,
                                'childId': childIndex,
                                'parentId': child.contract_period ? child : ""
                            }
                            temp.push(entityArray)
                        }
                        if (data === child.sp_id && child.contract_period !== null) {
                            entityArray1 = {
                                "address": child.address,
                                "con_no": child.con_no,
                                "contract_period": child.contract_period,
                                "email_id": child.email_id,
                                "s_p_status": child.s_p_status,
                                "sp_id": child.sp_id,
                                "sp_name": child.sp_name,
                                "sp_status_date": child.sp_status_date,
                                "unit_count": child.unit_count,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp1.push(entityArray1)
                        }
                    })
                }

                datavalue = {

                    parentid: data,
                    parent: temp1,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)
        }

    }, [paginationArray])


    useEffect(() => {
        if (paginationArray && paginationArray.length > 0) {
            let uniqueObjArray = [...new Map(paginationArray.map((item) => [item["sp_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.sp_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(paginationArray && paginationArray.length > 0 && paginationArray, { sp_id: uniqueIds[i] })
                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [paginationArray])



    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    const onChange = (key) => {
        console.log(key);
    };

    const onStatChange = (key) => {
        console.log(key);
    }

    const columns = [
        {
            title: 'Service Provider',
            ellipsis: true,
            width: '50px',
            dataIndex: 'sp_name',
            key: 'sp_name',
        },
        {
            title: 'Contact Number',
            ellipsis: true,
            width: '50px',
            dataIndex: 'con_no',
            key: 'con_no',
        },
        {
            title: 'Email-ID',
            ellipsis: true,
            width: '50px',
            dataIndex: 'email_id',
            key: 'email_id',
        },
        {
            title: 'Status',
            ellipsis: true,
            width: '50px',
            dataIndex: 's_p_status',
            key: 'email_id',
        },
        {
            title: 'Date',
            ellipsis: true,
            width: '50px',
            dataIndex: 'sp_status_date',
            key: 'email_id',
        },
    ];
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
      };
    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
      };
    return (
        <>
            <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            <div className="page-wrapper" id="page-wrapper">
                <div className="page-titles pb-0 pt-1">
                    <div className='row'>
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Service Provider Reports</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Service Provider Details</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-4 text-end">
                        <Button
                className="mx-1"
                type="primary"
                shape="round"
                style={{
                  display: filterTaskAcc ? "none" : "initial",
                }}
                onClick={filterHandle}
              >
                <FilterOutlined /> Filter
              </Button>
                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title="Full Screen Mode"><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pt-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className={localStorage.getItem('currentTheme')} id='only-restricted' style={{ position: 'relative', padding: "15px 5px" }}>

                                <Collapse defaultActiveKey={["1"]} onChange={onChange}
                                
                  style={{ display: exitCollapse ? "none" : "block" }}
                  className='report-collapse'>
                                    <Panel header="Service Provider Details" key={key}>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Service Provider : </b> </label>
                                                    <Select
                                                        allowClear={true}
                                                        onChange={(value, data) => {
                                                            setServiceProviderDetails({
                                                                ...serviceproviderdetails,
                                                                service_provider: data.children,
                                                                sp_id: value
                                                            })
                                                        }}
                                                        style={{ width: "100%", marginTop: '5px' }}
                                                        placeholder="Enter Service Provider"
                                                        value={serviceproviderdetails.service_provider || undefined}
                                                    >
                                                        {datalist.service_provider && datalist.service_provider.length > 0 && datalist.service_provider.map((item, i) => {
                                                            return (
                                                                <Option key={item.sp_id}>
                                                                    {item.sp_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>


                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>User : </b></label>

                                                    <Select allowClear={true}
                                                        placeholder='Enter User'
                                                        onChange={(value, data) => {
                                                            setServiceProviderDetails({
                                                                ...serviceproviderdetails,
                                                                user: data.children,
                                                                user_id: value
                                                            })
                                                        }}
                                                        style={{ width: "100%", marginTop: '5px' }}
                                                        value={serviceproviderdetails.user || undefined}

                                                    >
                                                        {datalist.user && datalist.user.length > 0 && datalist.user.map((item, i) => {
                                                            return (
                                                                <Option key={item.user_id}>
                                                                    {item.user_name}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>


                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Status : </b> </label>
                                                    <Select allowClear={true}
                                                        defaultValue="ALL"
                                                        onChange={(value, data) => {
                                                            setServiceProviderDetails({
                                                                ...serviceproviderdetails,
                                                                status: data.children
                                                            })

                                                        }}
                                                        style={{ width: "100%", marginTop: '5px' }}

                                                        value={serviceproviderdetails.status || undefined}

                                                    >
                                                        <Option key="All">All</Option>
                                                        {datalist.status && datalist.status.length > 0 && datalist.status.map((item, i) => {
                                                            return (
                                                                // data.unit ?
                                                                <Option key={item.s_p_status_id}>
                                                                    {item.s_p_status}
                                                                </Option>
                                                                // :""
                                                            );
                                                        })}
                                                    </Select>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 mt-2 popupbtncolour text-center'>
                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme') }onClick={onshow}
                                                icon={<EyeOutlined />}    >
                                                Show
                                            </Button>
                                        </div>
                                    </Panel>
                                </Collapse>
                                <Modal
                  onCancel={setcancelFilter}
                  footer={false}
                  title="Service provider details"
                  className={
                    "add-service-prv cons-report " +
                    localStorage.getItem("currentTheme")
                  }
                  visible={filterModalVisible}
                > 
                <div className='col-md-12'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <label><b>Service Provider : </b> </label>
                            <Select
                                allowClear={true}
                                onChange={(value, data) => {
                                    setServiceProviderDetails({
                                        ...serviceproviderdetails,
                                        service_provider: data.children,
                                        sp_id: value
                                    })
                                }}
                                style={{ width: "100%", marginTop: '5px' }}
                                placeholder="Enter Service Provider"
                                value={serviceproviderdetails.service_provider || undefined}
                            >
                                {datalist.service_provider && datalist.service_provider.length > 0 && datalist.service_provider.map((item, i) => {
                                    return (
                                        <Option key={item.sp_id}>
                                            {item.sp_name}
                                        </Option>
                                    );
                                })}
                            </Select>


                        </div>
                        <div className='col-md-4'>
                            <label><b>User : </b></label>

                            <Select allowClear={true}
                                placeholder='Enter User'
                                onChange={(value, data) => {
                                    setServiceProviderDetails({
                                        ...serviceproviderdetails,
                                        user: data.children,
                                        user_id: value
                                    })
                                }}
                                style={{ width: "100%", marginTop: '5px' }}
                                value={serviceproviderdetails.user || undefined}

                            >
                                {datalist.user && datalist.user.length > 0 && datalist.user.map((item, i) => {
                                    return (
                                        <Option key={item.user_id}>
                                            {item.user_name}
                                        </Option>
                                    )
                                })}
                            </Select>


                        </div>
                        <div className='col-md-4'>
                            <label><b>Status : </b> </label>
                            <Select allowClear={true}
                                defaultValue="ALL"
                                onChange={(value, data) => {
                                    setServiceProviderDetails({
                                        ...serviceproviderdetails,
                                        status: data.children
                                    })

                                }}
                                style={{ width: "100%", marginTop: '5px' }}

                                value={serviceproviderdetails.status || undefined}

                            >
                                <Option key="All">All</Option>
                                {datalist.status && datalist.status.length > 0 && datalist.status.map((item, i) => {
                                    return (
                                        // data.unit ?
                                        <Option key={item.s_p_status_id}>
                                            {item.s_p_status}
                                        </Option>
                                        // :""
                                    );
                                })}
                            </Select>

                        </div>
                    </div>
                </div>
                <div className='col-md-12 mt-2 popupbtncolour text-center'>
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} onClick={onshow}
                        icon={<EyeOutlined />}    >
                        Show
                    </Button>
                </div>
            </Modal>
                                {/* {tableshow === true ?
                                    <Collapse defaultActiveKey={["1"]} className='report-collapse' onChange={onStatChange} style={{ marginTop: '1%', backgroundColor: '#5daae3' }} >
                                        <Panel header="Overall Statistics" key={statkey}>
                                            <div className="col-md-12" style={{ marginLeft: '5%', marginRight: '5%' }}>
                                                <div className="row m-2">
                                                    <div className="col-md-4">
                                                        <Progress type="circle" percent={100} width={90} format={(percent) => `${percent} `} />
                                                        <h4 style={{ marginLeft: '5%' }}>Active</h4>

                                                    </div>
                                                    <div className="col-md-4">
                                                        <Progress type="circle" percent={10} width={90} status="exception" format={(percent) => `${percent} `} />
                                                        <h4 style={{ marginLeft: '5%' }}>Inactive</h4>

                                                    </div>
                                                    <div className="col-md-4">
                                                        <Progress type="circle" percent={70} width={90} strokeColor={{ '0%': '#f5b342', '100%': '#f5b342' }} format={(percent) => `${percent} `} />
                                                        <h4 style={{ marginLeft: '5%' }}>Blocked</h4>

                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse> : ''
                                } */}
                                {tableshow == true ?
                                    <div>
                                        <Card className='rounded-3'>
                                            <div className='ant-table-wrapper' style={{ marginTop: '1%' }}>
                                                <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                    <div className='ant-table-container'>
                                                        <div className='ant-table-content userprivclass1'>
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{ width: '10px' }}></col>
                                                                </colgroup>
                                                                <thead className="ant-table-thead antable-head">
                                                                    <tr>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '50px' }}> # </th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '230px' }}><center>Service Provider</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>No of Units</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '190px' }}><center>Contact Number</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '140px' }}><center>Email-ID</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '170px' }}><center>Address</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '240px' }}><center>Contract Period</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '110px' }}><center>Status</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '150px' }}><center>Date</center></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='ant-table-tbody'>
                                                                    {filteredArrayState && filteredArrayState.length > 0 ?
                                                                        filteredArrayState.map((item) => {
                                                                            return (
                                                                                <>
                                                                                    {titledata && titledata.map((itemdata, i) => {
                                                                                        let data = _.find(itemdata.parent, { sp_id: Number(item[0].sp_id) })
                                                                                        if (data != undefined) {
                                                                                            if (data.sp_id == itemdata.parentid) {
                                                                                                let temparray = []

                                                                                                return <>
                                                                                                    <tr>
                                                                                                        <td className='ant-table-cell'
                                                                                                            style={{ cursor: 'pointer' }}
                                                                                                            onClick={() => {
                                                                                                                if (itemdata.parent[0].unit_count > 0) {
                                                                                                                    setChildState(!childState)
                                                                                                                }
                                                                                                            }}
                                                                                                        ><center>{index + 1}</center></td>
                                                                                                        <td className='ant-table-cell'
                                                                                                            style={{ cursor: 'pointer' }}
                                                                                                            onClick={() => {
                                                                                                                if (itemdata.parent[0].unit_count > 0) {
                                                                                                                    setChildState(!childState)
                                                                                                                }
                                                                                                            }}
                                                                                                        >{itemdata.parent[0].sp_name}</td>
                                                                                                        <td className='ant-table-cell'
                                                                                                            style={{ cursor: 'pointer' }}
                                                                                                            onClick={() => {
                                                                                                                if (itemdata.parent[0].unit_count > 0) {
                                                                                                                    setChildState(!childState)
                                                                                                                }
                                                                                                            }}
                                                                                                        ><center>{itemdata.parent[0].unit_count}</center></td>
                                                                                                        <td className='ant-table-cell'
                                                                                                            style={{ cursor: 'pointer' }}
                                                                                                            onClick={() => {
                                                                                                                if (itemdata.parent[0].unit_count > 0) {
                                                                                                                    setChildState(!childState)
                                                                                                                }
                                                                                                            }}
                                                                                                        >{itemdata.parent[0].con_no}</td>
                                                                                                        <td className='ant-table-cell'
                                                                                                            style={{ cursor: 'pointer' }}
                                                                                                            onClick={() => {
                                                                                                                if (itemdata.parent[0].unit_count > 0) {
                                                                                                                    setChildState(!childState)
                                                                                                                }
                                                                                                            }}
                                                                                                        >{itemdata.parent[0].email_id}</td>
                                                                                                        <td className='ant-table-cell'
                                                                                                            style={{ cursor: 'pointer' }}
                                                                                                            onClick={() => {
                                                                                                                if (itemdata.parent[0].unit_count > 0) {
                                                                                                                    setChildState(!childState)
                                                                                                                }
                                                                                                            }}
                                                                                                        >{itemdata.parent[0].address}</td>
                                                                                                        <td className='ant-table-cell'
                                                                                                            style={{ cursor: 'pointer' }}
                                                                                                            onClick={() => {
                                                                                                                if (itemdata.parent[0].unit_count > 0) {
                                                                                                                    setChildState(!childState)
                                                                                                                }
                                                                                                            }}
                                                                                                        >{itemdata.parent[0].contract_period}</td>
                                                                                                        <td className='ant-table-cell'
                                                                                                            style={{ cursor: 'pointer' }}
                                                                                                            onClick={() => {
                                                                                                                if (itemdata.parent[0].unit_count > 0) {
                                                                                                                    setChildState(!childState)
                                                                                                                }
                                                                                                            }}
                                                                                                        >{itemdata.parent[0].s_p_status}</td>
                                                                                                        {itemdata.parent[0].s_p_status === 'Active' ? <td className='ant-table-cell'></td> :
                                                                                                            <td className='ant-table-cell'
                                                                                                                style={{ cursor: 'pointer' }}
                                                                                                                onClick={() => {
                                                                                                                    if (itemdata.parent[0].unit_count > 0) {
                                                                                                                        setChildState(!childState)
                                                                                                                    }
                                                                                                                }}
                                                                                                            ><center>{itemdata.parent[0].sp_status_date}</center></td>}
                                                                                                        <span hidden>{index = index + 1}</span>
                                                                                                    </tr>

                                                                                                    {childState === true ?
                                                                                                        itemdata.child.map((items, i) => {

                                                                                                            if (Number(item[0].sp_id) == items.sp_id) {

                                                                                                                return <tr>
                                                                                                                    <td className='ant-table-cell'><center>#</center></td>
                                                                                                                    <td className='ant-table-cell'>{items.sp_name}</td>
                                                                                                                    <td className='ant-table-cell'><center>{items.contract_period ? items.unit_count : ""}</center></td>
                                                                                                                    <td className='ant-table-cell'>{items.con_no == '--' ? <center>{items.con_no}</center> : items.con_no}</td>
                                                                                                                    <td className='ant-table-cell'>{items.email_id}</td>
                                                                                                                    <td className='ant-table-cell'>{items.address}</td>
                                                                                                                    <td className='ant-table-cell'>{items.contract_period}</td>
                                                                                                                    <td className='ant-table-cell'>{items.s_p_status}</td>
                                                                                                                    <td className='ant-table-cell'><center>{items.sp_status_date}</center></td>
                                                                                                                    {/* <span hidden>{index1 = index1 + 1}</span> */}
                                                                                                                </tr>

                                                                                                            }


                                                                                                        })
                                                                                                        : ''}


                                                                                                </>
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }) :
                                                                        <tr>
                                                                            <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                {index != 0 ?
                                                    <>
                                                        <div className='col-md-6'>
                                                            <label>Showing {pageSize * (current - 1) + 1} to {index} of {tablelist && tablelist.total_count} entries</label>
                                                        </div>
                                                        <div className='col-md-6 text-right'>
                                                            <Pagination
                                                                current={current}
                                                                showSizeChanger={true}
                                                                // showQuickJumper={true}
                                                                onShowSizeChange={() => {
                                                                    setCurrent(1)
                                                                    // setpageState(true)
                                                                }}
                                                                pageSizeOptions={[25, 50, 100]} hideOnSinglePage={tablelist && tablelist.total_count > 25 ? false : true} defaultPageSize={25}
                                                                onChange={(page, pageSizes) => {
                                                                    setpageState(true)
                                                                    setCurrent(pageSize !== pageSizes ? 1 : page);
                                                                    setPageSize(pageSizes)
                                                                }} total={tablelist && tablelist.total_count}
                                                            // showTotal={(total) => `Showing 1 to ${index} of ${tableData.total_count} entries`}
                                                            />
                                                        </div>
                                                    </> : ''}
                                            </div>
                                        </Card>
                                    </div>
                                    : ""}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default connect(mapStateToProps, {
    getdata,
    tabledata
})(ServiceProviderDetails);