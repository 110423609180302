export const fullscreenmode = () => {
    let topBar = document.getElementById('top-nav')
    let pageWrapper = document.getElementById('page-wrapper')
    let leftbar = document.getElementsByClassName('left-sidebar');
    let leftnone = leftbar[0];
    document.documentElement.requestFullscreen();
    topBar.style.display = 'none'
    pageWrapper.style.paddingTop = '0'
    pageWrapper.style.marginLeft = '0'
    leftnone.style.display = 'none'
}

export const fullscreenexit = () => {
    // if (!document.webkitIsFullScreen && !document.fullscreenElement) {
        let topBar = document.getElementById('top-nav')
        let pageWrapper = document.getElementById('page-wrapper')
        let leftbar = document.getElementsByClassName('left-sidebar');
        let leftnone = leftbar[0];
        document.exitFullscreen();
        topBar.style.display = 'block'
        pageWrapper.style.paddingTop = '70px'
        pageWrapper.style.marginLeft = '65px'
        leftnone.style.display = 'block'
    // }

}

export const handleKeyDown = (event) => {
    if (!document.webkitIsFullScreen && !document.fullscreenElement) {
        let topBar = document.getElementById('top-nav')
        let pageWrapper = document.getElementById('page-wrapper')
        let leftbar = document.getElementsByClassName('left-sidebar');
        let leftnone = leftbar[0];
        document.exitFullscreen();
        topBar.style.display = 'block'
        pageWrapper.style.paddingTop = '70px'
        pageWrapper.style.marginLeft = '65px'
        leftnone.style.display = 'block'
    }
  };