import React, { useEffect, useState, useRef, Fragment } from 'react'
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Card, Divider, Tabs, Select, Collapse, Tooltip, Modal, Input, Table, Button, DatePicker } from 'antd';
import SimpleReactValidator from "simple-react-validator";
import { reviewlist, reviewunitlist, reviewshowsettinglist, reviewsavecompliance } from './../../Store/Action/Transcations/Reviewsettings'
import DataTable from "react-data-table-component";
import moment from 'moment';
import { ExclamationCircleTwoTone, BookTwoTone, ArrowRightOutlined, ArrowLeftOutlined, PlayCircleOutlined, InfoCircleFilled, EyeOutlined, FullscreenOutlined, UpOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";
import { update } from 'lodash';
import { Toaster } from '../../Libs/Toaster';
import ReactDOM from 'react-dom';
import ScrollToTop from "react-scroll-to-top";


const _ = require("lodash");


const mapStateToProps = (state) => ({
    list: state.Reviewsetting.reviewsettings

})

const { Option } = Select;


const Reviewsetting = ({
    reviewlist,
    list,
    reviewunitlist,
    reviewshowsettinglist,
    reviewsavecompliance
}) => {
    const ref = useRef(null);
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    //  const entityid = localStorage.getItem('SelectedEntityid');
    const [finallegalEntity, setFinallegalentity] = useState()
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const currentdate = new Date();
    const { TabPane } = Tabs;
    const { Option, OptGroup } = Select;
    // const { Search } = Input;
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [unitFormSubmit, setUnitFromSubmit] = useState(false);
    const [selectUnit, setSelectUnit] = useState(false);
    const [selectShow, setSelectShow] = useState(false);
    const [value, setValue] = useState('1');
    const [assignpayload, setAssignPayload] = useState([])
    // const [editclick, setEditClick] = useState(false)
    const [updatedays, setUpdatedays] = useState([])
    const [updateduedate, setUpdateduedate] = useState([])
    console.log(updateduedate, "updateduedate2222");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true
    })
    const [collapseindex, setCollapseIndex] = useState([])
    const [updateevery, setUpdateevery] = useState([])
    const [updaterepeats, setUpdaterepeats] = useState([])
    console.log(assignpayload, 'assignpayload')
    console.log(updatedays, 'updatedays')
    const formValidator = useRef(new SimpleReactValidator());
    const [trigger, setTrigger] = useState([{
        comp_id: '',
        triggerInput: ''
    }])
    const [userDetails, setUserDetails] = useState({
        legal_entity_id: '',
        compliancefrequency: '',
        domain: '',
        timezone: '',
        units: [],
        domainName: '',
        dueDateList: '',
        legal_entity_name: '',
        bg_name: '',
        bg_id: ''
    })
    console.log(userDetails.domain, 'allArray2')

    const [item, setItem] = useState({
        value: ''
    })

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentUnits, setCurrentUnits] = useState([]);
    const [checkAll, setCheckAll] = useState('');
    const showModal = (u_ids) => {
        setIsModalVisible(true);
        let tempArrayName = [];
        let tempConsole = [];
        for (let k in u_ids) {
            tempConsole.push(_.filter(list.unitlist, { u_id: parseInt(u_ids[k]) }))
            //tempArrayName.push(tempConsole)
        }
        setCurrentUnits(tempConsole);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrentUnits([]);
    };
    const validator = useRef(new SimpleReactValidator());
    const validator1 = useRef(new SimpleReactValidator());
    const [checkedRow, setCheckedRow] = useState([]);
    const [date, setDate] = useState([])
    const [temparr, setTemparr] = useState([])
    console.log(temparr, "temparrtemparr");


    const [selectedRowsCount, SetSelectedRowsCount] = useState([])
    const [selected, setSelected] = useState([]);
    const [options, setOptions] = useState([{
        label: '',
        value: '',
    }])
    const [selectedUnitValues, setSelectedUnitValues] = useState([])
    const [entityid, setCurrentEntity] = useState("");
    console.log(entityid, 'entityid');
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false)
    const [temp, setTemp] = useState({
        tempData: [],
        parentIndexId: []
    });
    console.log(temp.tempData, 'temptemp');
    const { Panel } = Collapse;
    let repeatsEvery = null;
    console.log(temparr, 'temparr')
    const [data, setData] = useState();
    console.log(data, 'datadatadata');
    const [unitListData, setUnitListData] = useState([]);
    console.log(unitListData, 'unitListData')
    const [show, setShow] = useState(false)


    useEffect(() => {

        if (list.showsettinglist !== null) {
            let totalList = []
            let arr = [];
            let countrylisttempdata = [];
            let tempSetArray = [];
            let parId = []
            list && list.showsettinglist && list.showsettinglist.map(function (user) {
                if ((arr.indexOf(user.level_1_s_name) < 0))
                    arr.push(user.level_1_s_name);
            });

            arr.map((pmenu, index) => {
                let entityArray = []
                let tempArray = []

                if (pmenu) {

                    list.showsettinglist && list.showsettinglist.map((subMenuList, childIndex) => {
                        if (pmenu === subMenuList.level_1_s_name) {

                            entityArray = {
                                'id': childIndex,
                                'comp_id': subMenuList.comp_id,
                                'comp_name': subMenuList.comp_name,
                                'compfielaw_url': subMenuList.compfielaw_url,
                                'descp': subMenuList.descp,
                                'domain_id': subMenuList.domain_id,
                                'due_date_list': subMenuList.due_date_list,
                                'f_r_every': subMenuList.f_r_every,
                                'f_repeats_type_id': subMenuList.f_repeats_type_id,
                                'f_s_dates': subMenuList.f_s_dates,
                                'level_1_s_name': subMenuList.level_1_s_name,
                                'r_every': subMenuList.r_every,
                                'repeats_type_id': subMenuList.repeats_type_id,
                                's_dates': subMenuList.s_dates,
                                's_prov': subMenuList.s_prov,
                                'show_popup': subMenuList.show_popup,
                                'tz_date': subMenuList.tz_date,
                                'tz_name': subMenuList.tz_name,
                                'u_ids': subMenuList.u_ids,
                                'parentId': index,
                                'childId': childIndex,
                                'due_date_list_new': moment(new Date(subMenuList.due_date_list[0])).format('YYYY-MM-DD')

                            }
                            tempArray.push(entityArray)
                        }
                    })

                }
                totalList = {
                    'index': index,
                    'parentData': pmenu,
                    'child': tempArray
                }
                countrylisttempdata.push(totalList)
                tempSetArray.push(tempArray)
                parId.push(index);

            })
            setTemp({
                ...temp,
                tempData: countrylisttempdata,
                parentIndexId: parId

            })
            //  setData(tempSetArray)
            getData()
        }
    }, [list.showsettinglist])

    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        list.unitlist && list.unitlist && list.unitlist.map(function (user) {
            console.log(list.unitlist, 'list.unitlist');
            if ((arrunit.indexOf(user.div_name) < 0))
                arrunit.push(user.div_name);
        });

        arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []
            if (pmenu || pmenu == null) {
                list.unitlist && list.unitlist.length > 0 && list.unitlist.map((subunitlist) => {

                    if (pmenu == subunitlist.div_name) {
                        entityArrayunit = {
                            'u_id': subunitlist.u_id,
                            'u_code': subunitlist.u_code,
                            'u_name': subunitlist.u_name,
                            'address': subunitlist.address,
                            'g_name': subunitlist.g_name,
                            'div_name': subunitlist.div_name,
                            'label': subunitlist.u_code + ' - ' + subunitlist.u_name + ' - ' + subunitlist.address,
                            'values': subunitlist.u_id
                        }
                        tempArrayunit.push(entityArrayunit)
                    }
                })
            }
            totalListunit = {
                'label': pmenu == null ? 'Others' : pmenu,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setUnitListData(countrylisttempdataunit)


    }, [list.unitlist])


    const findById = (array, id) => {

        return array.findIndex((d) => d.id === id);
    }
    // console.log(list.unitlist,'list.unitlist');
    const getData = () => {
        let countrylisttempdataNew = [];
        let arrNew = [];
        list && list.showsettinglist && list.showsettinglist.map(function (user) {
            if ((arrNew.indexOf(user.level_1_s_name) < 0))
                arrNew.push(user.level_1_s_name);
        });

        arrNew.map((pmenu, index) => {
            let entityArrayNew = [];
            let tempArraryNew = [];
            if (pmenu) {
                list.showsettinglist && list.showsettinglist.map((subMenuList, childIndex) => {
                    if (pmenu === subMenuList.level_1_s_name) {
                        entityArrayNew = {
                            'id': childIndex,
                            'comp_id': subMenuList.comp_id,
                            'comp_name': subMenuList.comp_name,
                            'compfielaw_url': subMenuList.compfielaw_url,
                            'descp': subMenuList.descp,
                            'domain_id': subMenuList.domain_id,
                            'due_date_list': subMenuList.due_date_list,
                            'f_r_every': subMenuList.f_r_every,
                            'f_repeats_type_id': subMenuList.f_repeats_type_id,
                            'f_s_dates': subMenuList.f_s_dates,
                            'level_1_s_name': subMenuList.level_1_s_name,
                            'r_every': subMenuList.r_every,
                            'repeats_type_id': subMenuList.repeats_type_id,
                            's_dates': subMenuList.s_dates,
                            's_prov': subMenuList.s_prov,
                            'show_popup': subMenuList.show_popup,
                            'tz_date': subMenuList.tz_date,
                            'tz_name': subMenuList.tz_name,
                            'u_ids': subMenuList.u_ids,
                            'parentId': index,
                            'childId': childIndex,
                            'due_date_list_new': moment(new Date(subMenuList.due_date_list[0])).format('YYYY-MM-DD')

                        }
                        tempArraryNew.push(entityArrayNew)
                        updaterepeats[childIndex] = subMenuList.repeats_type_id
                        updateevery[childIndex] = subMenuList.r_every
                        updateduedate[childIndex] = moment(new Date(subMenuList.due_date_list[0])).format('YYYY-MM-DD')
                        updatedays[childIndex] = subMenuList.s_dates[0].trigger_before_days

                    }
                })
            }
            countrylisttempdataNew.push(tempArraryNew)
        })
        var merged = [].concat.apply([], countrylisttempdataNew);
        setData(merged)
        setUpdaterepeats([...updaterepeats])
        setUpdateevery([...updateevery])
        setUpdateduedate([...updateduedate])
        setUpdatedays([...updatedays])
    }

    const handleTitleEditable = (field) => (row) => (parentId) => (index) => (e) => {

        const newRow = { ...row };
        newRow[field] = e.target.value;
        const newData = data.slice(0);
        newData[findById(data, row.id)] = newRow;

        setData(newData);
    };


    const addSelectedDataValues = (checked, comp_id, childId, parentId, record) => {

        var ele = document.getElementsByName('allchecked');
        var elechild = document.getElementsByClassName('checkclass' + parentId);
        var childcheckbox = document.getElementsByClassName('checkclasschild' + parentId);
        var checkbox = document.getElementById(parentId);
        let array
        let array1
        let array2
        let tempArray = temparr
        if (checkedRow === true) {
            if (checked === true) {
                // array = _.find(data, { comp_id: comp_id });
                // tempArray.push(array)
                array1 = _.filter(temp.tempData, { index: parentId });
                array = _.find(data, { comp_id: comp_id });
                tempArray.push(array)
                array2 = _.filter(tempArray, { parentId: parentId });
                if (array2.length === array1[0].child.length) {
                    elechild[0].checked = true;
                }

            } else {

                for (let i in tempArray) {
                    if (tempArray[i].comp_id === comp_id) {
                        tempArray.splice(i, 1)
                    }
                    if (data.length !== temparr.length) {
                        setCheckedRow(false)
                        // for (var j = 0; j < ele.length; j++) {
                        //     if (ele[j].type === 'checkbox')
                        //         ele[j].checked = false;
                        // }
                        elechild[0].checked = false;
                    }
                }
            }
            setTemparr([...tempArray])
            SetSelectedRowsCount([...tempArray])
        } else {

            if (checked === true) {
                array1 = _.filter(temp.tempData, { index: parentId });
                array = _.find(data, { comp_id: comp_id });
                tempArray.push(array)
                array2 = _.filter(tempArray, { parentId: parentId });
                if (array2.length === array1[0].child.length) {
                    elechild[0].checked = true;
                }
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].comp_id === comp_id) {
                        tempArray.splice(i, 1)
                    }
                }
                elechild[0].checked = false;
            }
            setTemparr([...tempArray])
            SetSelectedRowsCount([...tempArray])
        }
        if (moment(record.due_date_list[0]).format('YYYY-MM-DD') > moment(currentdate).format('YYYY-MM-DD')) {

            // setUpdateduedate([...updateduedate])
            duedateValidation[record.childId] = undefined
            setDuedateValidation([...duedateValidation])
        } else {
            duedateValidation[record.childId] = "Due date should not less than today's date for compliance - " + record.comp_name
            setDuedateValidation([...duedateValidation])
        }

        if (record.s_dates[0].trigger_before_days != 0 && record.s_dates[0].trigger_before_days < 101 && record.s_dates[0].trigger_before_days != '') {
            triggerValidation[record.childId] = undefined
            setTriggerValidation([...triggerValidation])
        } else {
            triggerValidation[record.childId] = 'Trigger Before Days should be 1 to 100 for ' + record.comp_name
            setTriggerValidation([...triggerValidation])
            setTempStateValidation(true)
        }


    }
    const addAllSelectedDataValues = (checked, currentParId, parentData, childDetails, record) => {
        console.log(childDetails, "childDetailschildDetails");
        let array
        let tempArray = temparr
        var ele = document.getElementsByName('checked' + currentParId);
        if (checked === true) {
            setCheckedRow(true)
            array = _.filter(data, { parentId: currentParId });
            console.log(tempArray, 'tempArray')
            for (let i in array) {
                let existsCheck = _.find(temparr, { comp_id: array[i].comp_id });
                if (!existsCheck) {
                    tempArray.push(array[i])
                }

            }

            setTemparr([...tempArray])
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            for (var x = tempArray.length - 1; x >= 0; x--) {
                if (tempArray[x].parentId === currentParId) {
                    tempArray.splice(x, 1)
                }
            }
            setTemparr([...tempArray])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
        childDetails && childDetails.length > 0 && childDetails.map((item) => {
            if (moment(item.due_date_list[0]).format('YYYY-MM-DD') > moment(currentdate).format('YYYY-MM-DD')) {
                setUpdateduedate([...updateduedate])
                duedateValidation[item.childId] = undefined
                setDuedateValidation([...duedateValidation])
            } else {
                duedateValidation[item.childId] = "Due date should not less than today's date for compliance - " + item.comp_name
                setDuedateValidation([...duedateValidation])
            }

            if (item.s_dates[0].trigger_before_days != 0 && item.s_dates[0].trigger_before_days < 101 && item.s_dates[0].trigger_before_days != '') {
                triggerValidation[item.childId] = undefined
                setTriggerValidation([...triggerValidation])
            } else {
                triggerValidation[item.childId] = 'Trigger Before Days should be 1 to 100 for ' + item.comp_name
                setTriggerValidation([...triggerValidation])
                setTempStateValidation(true)
            }
        })

    }


    const [triggerValidation, setTriggerValidation] = useState([]);
    console.log(triggerValidation, "triggerValidation");
    const [duedateValidation, setDuedateValidation] = useState([])
    console.log(duedateValidation, "duedateValidation");
    console.log(duedateValidation.filter(Boolean).length == 0, "hhhhhhhhh");
    const [triggerValidationDue, setTriggerValidationDue] = useState([]);
    const [tempStateValidation, setTempStateValidation] = useState(false);
    const customValidation = (currentValue, record) => {
        if (currentValue < 1) {
            Toaster.error('Trigger Before Days should be 1 to 100 ' + record.comp_name)
        } else if (currentValue > 101) {
            Toaster.error('Trigger Before Days should not exceed 100 for ' + record.comp_name)
        } else {
            setTempStateValidation(false);
        }
    }


    const rowSelection = {

        // columnTitle: <input type="checkbox" name="allchecked" className={'checkclass'} />,
        renderCell: (checked, record, index, originNode) => {
            return (
                <Fragment>
                    <input type="checkbox"
                        name={'checked' + record.parentId}
                        className={'tempclass checkclasschild' + record.parentId}
                        onClick={(e) => {
                            console.log(e.target.checked, 'e.target.checked')
                            let checked = e.target.checked;
                            addSelectedDataValues(
                                checked,
                                record.comp_id,
                                record.childId,
                                record.parentId,
                                record
                            );
                        }}
                    />
                </Fragment>
            )
        }

    }
    const columns = [

        {
            title: 'Statutory Provision',
            dataIndex: 's_prov',
            key: 's_prov',
            ellipsis: true,
            width: '180px'
        },
        {
            title: 'Compliance Task',
            dataIndex: 'comp_name',
            key: 'comp_name',
            //  ellipsis: true,
            width: '110px',
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title={record.descp}>
                            <ExclamationCircleTwoTone />
                        </Tooltip>&nbsp;
                        <Tooltip title={"Compfie Law Knowledge Portal"}>
                            <span style={{ whiteSpace: 'unset', marginTop: '-20px' }} > {record.compfielaw_url ? <a href={`${record.compfielaw_url}`}><BookTwoTone /></a> : ''}</span>
                        </Tooltip>&nbsp;
                        <Tooltip title={record.comp_name}>
                            <span>{record.comp_name}</span>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            title: 'Repeats By',
            dataIndex: false,
            key: false,
            ellipsis: true,
            width: '100px',
            render: (text, record) => {
                if (record.repeats_type_id == 1) {
                    repeatsEvery = ' Days'
                } else if (record.repeats_type_id == 2) {
                    repeatsEvery = ' Months'
                } else if (record.repeats_type_id == 3) {
                    repeatsEvery = ' Years'
                }
                return 'Every ' + record.r_every + repeatsEvery
            }
        },
        {
            title: <label><span>Repeats Every</span><span style={{ color: "red" }}>*</span></label>,
            dataIndex: false,
            key: false,
            ellipsis: true,
            width: '140px',
            render: (text, row) => {
                for (var i = 0; i < temparr.length; i++) {
                    if (temparr && temparr.length > 0) {
                        if (temparr && temparr.filter(e => (e.comp_id == row.comp_id)).length > 0) {
                            return (
                                <>
                                    {/* <label>{row.r_every} </label> */}
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                        <div>
                                            <Input name='triggerInputUpdate' autoComplete={'off'} maxLength={3} style={{ width: "40px", textAlign: 'center' }} className="form-control"
                                                defaultValue={updateevery[row.childId]} key={updateevery[row.childId]}
                                                // onChange={(e) => {
                                                //     checkevery(e.target.value, row.r_every, row.childId)
                                                //     // if ( updateevery[row.childId] < e.target.value ) {
                                                //     //     toast.error("Repeats type should not exceed in actual value")
                                                //     // }
                                                // }}
                                                onBlur={(e, i) => {
                                                    if (userDetails.compliancefrequency == 3) {
                                                        let repeatvalue;
                                                        if (row.repeats_type_id == updaterepeats[row.childId]) {

                                                            if (row.r_every < e.target.value) {
                                                                Toaster.error('Repeats Type should not exceed in Actual Value');
                                                                updateevery[row.childId] = []
                                                                setUpdateevery([...updateevery])
                                                            } else {
                                                                let temp1 = []
                                                                temp1.push(e.target.value)
                                                                if (row.childId in updateevery) {
                                                                    let index = updateevery.indexOf(row)
                                                                    updateevery[row.childId] = [];
                                                                    if (index == -1) {
                                                                        updateevery[row.childId] = e.target.value;
                                                                    }
                                                                } else {
                                                                    updateevery[row.childId] = [];
                                                                    updateevery[row.childId] = e.target.value
                                                                }
                                                                setUpdateevery([...updateevery])
                                                            }
                                                        } else {
                                                            if (updaterepeats[row.childId] == 1 && row.repeats_type_id == 2) {
                                                                repeatvalue = row.r_every * 30
                                                                if (repeatvalue < e.target.value) {
                                                                    Toaster.error('Repeats Type should not exceed in Actual Value');
                                                                    updateevery[row.childId] = row.r_every
                                                                    setUpdateevery([...updateevery])
                                                                } else {
                                                                    let temp1 = []
                                                                    temp1.push(e.target.value)
                                                                    if (row.childId in updateevery) {
                                                                        let index = updateevery.indexOf(row)
                                                                        updateevery[row.childId] = [];
                                                                        if (index == -1) {
                                                                            updateevery[row.childId] = e.target.value;
                                                                        }
                                                                    } else {
                                                                        updateevery[row.childId] = [];
                                                                        updateevery[row.childId] = e.target.value
                                                                    }
                                                                    setUpdateevery([...updateevery])
                                                                }
                                                            } else if (updaterepeats[row.childId] == 1 && row.repeats_type_id == 3) {
                                                                repeatvalue = row.r_every * 365
                                                                if (repeatvalue < e.target.value) {
                                                                    Toaster.error('Repeats Type should not exceed in Actual Value');
                                                                    updateevery[row.childId] = row.r_every
                                                                    setUpdateevery([...updateevery])
                                                                } else {
                                                                    let temp1 = []
                                                                    temp1.push(e.target.value)
                                                                    if (row.childId in updateevery) {
                                                                        let index = updateevery.indexOf(row)
                                                                        updateevery[row.childId] = [];
                                                                        if (index == -1) {
                                                                            updateevery[row.childId] = e.target.value;
                                                                        }
                                                                    } else {
                                                                        updateevery[row.childId] = [];
                                                                        updateevery[row.childId] = e.target.value
                                                                    }
                                                                    setUpdateevery([...updateevery])
                                                                }

                                                            } else if (updaterepeats[row.childId] == 2 && row.repeats_type_id == 3) {
                                                                repeatvalue = row.r_every * 12
                                                                if (repeatvalue < e.target.value) {
                                                                    Toaster.error('Repeats Type should not exceed in Actual Value');
                                                                    updateevery[row.childId] = row.r_every
                                                                    setUpdateevery([...updateevery])
                                                                } else {
                                                                    let temp1 = []
                                                                    temp1.push(e.target.value)
                                                                    if (row.childId in updateevery) {
                                                                        let index = updateevery.indexOf(row)
                                                                        updateevery[row.childId] = [];
                                                                        if (index == -1) {
                                                                            updateevery[row.childId] = e.target.value;
                                                                        }
                                                                    } else {
                                                                        updateevery[row.childId] = [];
                                                                        updateevery[row.childId] = e.target.value
                                                                    }
                                                                    setUpdateevery([...updateevery])
                                                                }
                                                            } else {
                                                                let temp1 = []
                                                                temp1.push(e.target.value)
                                                                if (row.childId in updateevery) {
                                                                    let index = updateevery.indexOf(row)
                                                                    updateevery[row.childId] = [];
                                                                    if (index == -1) {
                                                                        updateevery[row.childId] = e.target.value;
                                                                    }
                                                                } else {
                                                                    updateevery[row.childId] = [];
                                                                    updateevery[row.childId] = e.target.value
                                                                }
                                                                setUpdateevery([...updateevery])
                                                            }
                                                        }
                                                    } else {
                                                        let temp1 = []
                                                        temp1.push(e.target.value)
                                                        if (row.childId in updateevery) {
                                                            let index = updateevery.indexOf(row)
                                                            updateevery[row.childId] = [];
                                                            if (index == -1) {
                                                                updateevery[row.childId] = e.target.value;
                                                            }
                                                        } else {
                                                            updateevery[row.childId] = [];
                                                            updateevery[row.childId] = e.target.value
                                                        }
                                                        setUpdateevery([...updateevery])
                                                    }


                                                    // let temp1 = []
                                                    // temp1.push(e.target.value)
                                                    // if (row.childId in updateevery) {
                                                    //     let index = updateevery.indexOf(row)
                                                    //     updateevery[row.childId] = [];
                                                    //     if (index == -1) {
                                                    //         updateevery[row.childId] = e.target.value;
                                                    //     }
                                                    // } else {
                                                    //     updateevery[row.childId] = [];
                                                    //     updateevery[row.childId] = e.target.value
                                                    // }
                                                    // setUpdateevery([...updateevery])
                                                    // setUpdateevery([...updateevery, ...temp1])
                                                    //  validationFunction(e.target.value, row.childId, row.parentId)                                                                
                                                }}

                                            />
                                            {formValidator.current.message(
                                                'r_every',
                                                updateevery[row.childId],
                                                ['required', { regex: /^[0-9]*$/ }],

                                                {
                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Repeats Every Required',
                                                        regex: 'Enter only digits'

                                                    }
                                                })}
                                        </div>
                                        <div className=' mLReview'>
                                            <Select style={{ height: "35px", width: '90px' }} defaultValue={updaterepeats[row.childId].toString()}
                                                onChange={(e, i) => {
                                                    let repeatvalue;
                                                    if (userDetails.compliancefrequency == 3) {
                                                        updateevery[row.childId] = ''
                                                        setUpdateevery([...updateevery])
                                                    }
                                                    if (row.childId in updaterepeats) {
                                                        let index = updaterepeats.indexOf(row)
                                                        updaterepeats[row.childId] = [];
                                                        if (index == -1) {
                                                            updaterepeats[row.childId] = parseInt(e);
                                                        }
                                                    } else {
                                                        updaterepeats[row.childId] = [];
                                                        updaterepeats[row.childId] = parseInt(e)
                                                    }
                                                    setUpdaterepeats([...updaterepeats])

                                                    // if (row.repeats_type_id == e) {
                                                    //     if (row.childId in updaterepeats) {
                                                    //         let index = updaterepeats.indexOf(row)
                                                    //         updaterepeats[row.childId] = [];
                                                    //         if (index == -1) {
                                                    //             updaterepeats[row.childId] = parseInt(e);
                                                    //         }
                                                    //     } else {
                                                    //         updaterepeats[row.childId] = [];
                                                    //         updaterepeats[row.childId] = parseInt(e)
                                                    //     }
                                                    //     setUpdaterepeats([...updaterepeats])
                                                    // } else {
                                                    //     if (e == 2 && row.repeats_type_id == 3) {
                                                    //         repeatvalue = row.r_every * 12
                                                    //         if (repeatvalue < updateevery[row.childId]) {
                                                    //             Toaster.error('valueGreater');
                                                    //         }
                                                    //     } else if (e == 1 && row.repeats_type_id == 2) {
                                                    //         repeatvalue = row.r_every * 30
                                                    //         if (repeatvalue < updateevery[row.childId]) {
                                                    //             Toaster.error('valueGreater');                                                              
                                                    //         }
                                                    //     } else if (e == 1 && row.repeats_type_id == 3) {
                                                    //         repeatvalue = row.r_every * 365
                                                    //         if (repeatvalue < updateevery[row.childId]) {
                                                    //             Toaster.error('valueGreater');
                                                    //         }
                                                    //     }
                                                    // }
                                                }}>
                                                {/* {row.repeats_type_id == 1 ? <Option value="1">Days</Option> : row.repeat_type_id == 2 ? <><Option value="1">Days</Option> <Option value="2">Months</Option></> : row.repeat_type_id == 3 ? <><Option value="1">Days</Option> <Option value="2">Months</Option> </> : ''} */}
                                                {/* <Option value="1">Days</Option>
                                                <Option value="2">Months</Option>
                                                <Option value="3">Years</Option> */}
                                                {getoption(row.repeats_type_id)}



                                            </Select>
                                            {/* <select className='form-control' style={{ height: "35px" }}><option value="1" selected={row.repeats_type_id == 1}>Days</option><option value="2" selected={row.repeats_type_id == 2}>Months</option><option value="3" selected={row.repeats_type_id == 3}>Years</option></select> */}
                                        </div>
                                    </div>
                                </>


                            )
                        }
                    }
                }
            }
        },
        {
            title: <label><span>Due Date</span><span style={{ color: "red", marginLeft: "5px" }}>*</span></label>,
            dataIndex: false,
            key: false,
            ellipsis: true,
            align: 'center',
            width: '110px',
            render: (text, row) => {
                for (var i = 0; i < temparr.length; i++) {

                    if (temparr && temparr.length > 0) {

                        if (temparr && temparr.filter(e => (e.comp_id == row.comp_id)).length > 0) {

                            return (
                                <>
                                    {/* <label>{row.due_date_list[0]} </label> */}
                                    <div>

                                        <DatePicker format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '125px' }} allowClear={false} className="form-control"
                                            value={updateduedate[row.childId] ? moment(updateduedate[row.childId]) : ''}
                                            onChange={(date, dateString) => {
                                                console.log('onchangein')
                                                let temp = []
                                                temp.push(dateString)
                                                if (row.childId in updateduedate) {
                                                    let index = updateduedate.indexOf(row)
                                                    updateduedate[row.childId] = [];
                                                    if (index == -1) {
                                                        updateduedate[row.childId] = dateString;
                                                    }
                                                } else {
                                                    updateduedate[row.childId] = [];
                                                    updateduedate[row.childId] = dateString
                                                }
                                                setUpdateduedate([...updateduedate])
                                                if (dateString > moment(currentdate).format('DD-MMM-YYYY')) {
                                                    duedateValidation[row.childId] = undefined
                                                    setDuedateValidation([...duedateValidation])

                                                }
                                            }}
                                            disabledDate={(current) => {
                                                return moment().add(-1, 'days') >= current
                                            }}

                                        //     onClick={(date, dateString) => {
                                        //         console.log(dateString,"dateString222");
                                        //         console.log(temparr[0].due_date_list[0],"uhjjjjjjjjj");
                                        //         let temp = []
                                        //         temp.push(dateString)
                                        //         if ( temparr[0].due_date_list[0] > moment(currentdate).format('DD-MMM-YYYY')) {
                                        //             console.log("6666666");
                                        //         if (row.childId in updateduedate) {
                                        //             let index = updateduedate.indexOf(row)
                                        //             updateduedate[row.childId] = [];
                                        //             if (index == -1) {
                                        //                 updateduedate[row.childId] = dateString;
                                        //             }

                                        //         } else {
                                        //             updateduedate[row.childId] = [];
                                        //             updateduedate[row.childId] = dateString
                                        //         }
                                        //         setUpdateduedate([...updateduedate])
                                        //         duedateValidation[row.childId] = undefined
                                        //         setDuedateValidation([...duedateValidation])
                                        //     } else {
                                        //         duedateValidation[row.childId] = "Due date should not less than today's date for compliance - " + row.comp_name
                                        //         setDuedateValidation([...duedateValidation])
                                        //     }
                                        // }}
                                        />
                                        {formValidator.current.message(
                                            'due_date',
                                            updateduedate[row.childId],
                                            ['required'],

                                            {
                                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Due Date Required',
                                                    // after_or_equal: 'Due Date Should not be less than today Date'
                                                }
                                            })}
                                    </div>

                                </>
                            )



                            // }

                        }
                    }
                }
            }
        },
        {
            title: <label><span>Trigger Before (Days)</span><span style={{ color: "red" }}>*</span></label>,
            dataIndex: false,
            width: '80px',
            align: 'center',
            render: (text, row) => {
                for (var i = 0; i < temparr.length; i++) {
                    // for (let i in temparr) {
                    if (temparr && temparr.length > 0) {
                        //  temparr.map((item, i) => {
                        //    setLoopDays(temparr[i].comp_id)
                        if (temparr && temparr.filter(e => (e.comp_id == row.comp_id)).length > 0) {
                            return (
                                <>
                                    {/* <label>{row.s_dates[0].trigger_before_days} </label> */}
                                    {/* {temparr[i].comp_id == row.comp_id ? */}
                                    <div className='text-center'>
                                        <div>
                                            <Input name='triggerInputUpdate' maxLength={3} autoComplete={'off'} style={{ width: "60px" }} className="form-control" placeholder='Days'
                                                defaultValue={updatedays[row.childId]} key={updatedays[row.childId]}
                                                onBlur={(e) => {
                                                    if (e.target.value != 0 && e.target.value < 101) {
                                                        let temp = []
                                                        temp.push(e.target.value)
                                                        if (row.childId in updatedays) {
                                                            let index = updatedays.indexOf(row)
                                                            updatedays[row.childId] = [];
                                                            if (index == -1) {
                                                                updatedays[row.childId] = e.target.value;
                                                            }
                                                        } else {
                                                            updatedays[row.childId] = [];
                                                            updatedays[row.childId] = e.target.value
                                                        }
                                                        setUpdatedays([...updatedays])
                                                        triggerValidation[row.childId] = undefined
                                                        setTriggerValidation([...triggerValidation])
                                                        //setUpdatedays([...updatedays, ...temp])
                                                        // customValidation(updatedays[row.childId], row)
                                                    } else {
                                                        triggerValidation[row.childId] = 'Trigger Before Days should be 1 to 100 for ' + row.comp_name
                                                        setTriggerValidation([...triggerValidation])
                                                        setTempStateValidation(true)

                                                    }

                                                }}
                                            ></Input>
                                            {formValidator.current.message(
                                                'repeats_every',
                                                updatedays[row.childId],
                                                ['required', { regex: /^[0-9]*$/ }],

                                                {
                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Trigger Before Days Required',
                                                        regex: 'Enter only digits',
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </>
                            )



                            // }

                        }
                    }

                }
            }
        },
        {
            title: 'Applicable Vendors',
            dataIndex: false,
            width: '70px',
            align: 'center',
            fixed: 'right',
            render: (text, row) => {
                return (
                    <Link style={{ padding: "0px 30px" }} onClick={() => { showModal(row.u_ids) }}>{`${row.u_ids.length}/${all.length}`}</Link>
                )
            }
        },
    ];

    //Store Select Into array in state
    const [all, setAll] = useState([]);
    const [selectcheckbox, Setselectcheckbox] = useState('block');
    const [unselectcheckbox, Setunselectcheckbox] = useState('none');

    const HandelChange = (obj) => {
        let allArray = [];
        if (obj.includes('all')) {
            if (list && list.unitlist && list.unitlist.length > 0) {
                let temp = []
                for (let i in list.unitlist) {
                    let unitFinalCode = list.unitlist[i].u_code + ' ' + list.unitlist[i].u_name + ' ' + list.unitlist[i].address
                    temp.push(list.unitlist[i].u_id)
                    allArray.push(list.unitlist[i].u_id)

                }
                setAll(temp)
                setUserDetails({
                    ...userDetails,
                    units: allArray
                })
            }
            Setselectcheckbox('none');
            Setunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setUserDetails({
                ...userDetails,
                units: []
            })
            setAll(temp);
            Setselectcheckbox('block');
            Setunselectcheckbox('none');
        } else {
            if (obj.length == list.unitlist.length) {
                Setselectcheckbox('none');
                Setunselectcheckbox('block');
            } else {
                Setselectcheckbox('block');
                Setunselectcheckbox('none');
            }
            setAll(obj)
            setUserDetails({
                ...userDetails,
                units: obj
            })
        }

        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray.filter(function(el) { return el != "unselect"; }) : obj)
        // })
        // return obj;

    };
    // const showDetailsOnSubmit = () => {
    //     setAddFormSubmit(true);
    //     setUnitFromSubmit(false);
    //     if (validator.current.allValid()) {
    //         setSelectShow(true)
    //         setShow(true)
    //         const unitlist = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     "GetReviewSettingsUnitFilters",
    //                     {
    //                         "le_id": entityid,
    //                         "d_id": parseInt(userDetails.domain),
    //                         "tz_id": Number(list.time_zone_le && list.time_zone_le[0].tz_id),
    //                     }
    //                 ]
    //             }
    //         ]
    //         reviewunitlist({
    //             payload: unitlist,
    //             paramid: paramid
    //         })
    //     }
    // }
    const userDetailOnSubmit = () => {
        setAddFormSubmit(false);
        setUnitFromSubmit(true);
        if (validator1.current.allValid()) {
            setSelectUnit(true)

            setValue('2')
            const showreviewsettinglist = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetReviewSettingsComplianceFilters",
                        {
                            "le_id": entityid,
                            "d_id": parseInt(userDetails.domain),
                            "unit_ids": userDetails.units,
                            "f_id": parseInt(userDetails.compliancefrequency),
                            "sno": 0
                        }
                    ]
                }
            ]
            reviewshowsettinglist({
                payload: showreviewsettinglist,
                paramid: paramid
            })
        }

    }

    const previous = () => {
        setValue('1')
        setTemparr([])
        var getchildcheck = document.getElementsByClassName('tempclass')
        for (var jk = 0; jk < getchildcheck.length; jk++) {
            if (getchildcheck[jk].type === 'checkbox')
                getchildcheck[jk].checked = false;
        }
    }

    const saveRecords = (e) => {
        e.preventDefault()
        setAddFormSubmit1(true)
        if (triggerValidation.filter(Boolean).length == 0 && duedateValidation.filter(Boolean).length == 0) {
            if (formValidator.current.allValid()) {
                const savecompliancelist = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request":

                            [
                                "SaveReviewSettingsCompliance",

                                {
                                    "le_id": entityid,
                                    "rs_compliances": assignpayload

                                }
                            ]
                    }
                ]
                if (temparr.length > 0) {
                    reviewsavecompliance({
                        payload: savecompliancelist,
                        paramid: paramid
                    })
                } else {
                    toast.warning("Select any one compliance")
                }


            }
        } else {

            toast.error(triggerValidation.filter(Boolean)[triggerValidation.filter(Boolean).length - 1]);
            toast.error(duedateValidation.filter(Boolean)[duedateValidation.filter(Boolean).length - 1]);


        }


    }

    const getoption = (value) => {
        if (value == 1) {
            return <><Option value="1">Days</Option></>
        } else if (value == 2) {
            return <><Option value="1">Days</Option> <Option value="2">Months</Option></>
        } else if (value == 3) {
            return <><Option value="1">Days</Option> <Option value="2">Months</Option> <Option value="3">Years</Option></>
        } else {
            return <><Option value="1">Days</Option> <Option value="2">Months</Option> <Option value="3">Years</Option></>
        }
    }

    useEffect(() => {

        if (temparr && temparr.length > 0) {
            let tempArray = []
            for (let i in temparr) {
                let getChildId = _.filter(data, { comp_id: temparr[i].comp_id })
                let childIdCurrent = getChildId[0].childId;
                tempArray.push({
                    "comp_id": temparr[i].comp_id,
                    "le_id": entityid,
                    "d_id": parseInt(userDetails.domain),
                    "f_id": parseInt(userDetails.compliancefrequency),
                    "unit_ids": userDetails.units,
                    "repeat_by": updateevery[childIdCurrent] == '' ? temparr[i].r_every : parseInt(updateevery[childIdCurrent]),//temparr[i].r_every !== null ? updateevery[childIdCurrent] == '' ? temparr[i].r_every : parseInt(updateevery[childIdCurrent]) : parseInt(trigger[0].triggerInput),
                    "due_date": moment(updateduedate[childIdCurrent] == '' ? temparr[i].due_date_list[0] : updateduedate[childIdCurrent]).format("DD-MMM-YYYY"),//moment(temparr[i].due_date_list[0] != null ? updateduedate[childIdCurrent] == '' ? temparr[i].due_date_list[0] : (updateduedate[childIdCurrent]) : (trigger[0].triggerInput)).format("DD-MMM-YYYY"),
                    "repeat_type_id": updaterepeats[childIdCurrent] == '' ? temparr[i].repeats_type_id : parseInt(updaterepeats[childIdCurrent]),
                    "trigger_before_days": updatedays[childIdCurrent] == '' ? temparr[i].s_dates[0].trigger_before_days : parseInt(updatedays[childIdCurrent]),//temparr[i].s_dates[0].trigger_before_days != null ? updatedays[childIdCurrent] == '' ? temparr[i].s_dates[0].trigger_before_days : parseInt(updatedays[childIdCurrent]) : parseInt(trigger[0].triggerInput),
                    "statu_dates": [
                        {
                            "statutory_date": updateduedate[childIdCurrent] == '' ? temparr[i].f_s_dates[0].statutory_date : parseInt(moment(updateduedate[childIdCurrent]).format("DD")),//temparr[i].f_s_dates[0].statutory_date != null ? updateduedate[childIdCurrent] == '' ? temparr[i].f_s_dates[0].statutory_date : parseInt(moment(updateduedate[childIdCurrent]).format("DD")) : parseInt(trigger[0].triggerInput),
                            "statutory_month": parseInt(moment(updateduedate[childIdCurrent]).format("MM")),
                            "trigger_before_days": updatedays[childIdCurrent] == '' ? temparr[i].s_dates[0].trigger_before_days : parseInt(updatedays[childIdCurrent]),//temparr[i].f_s_dates[0].trigger_before_days != null ? updatedays[childIdCurrent] == '' ? temparr[i].f_s_dates[0].trigger_before_days : parseInt(updatedays[childIdCurrent]) : parseInt(trigger[0].triggerInput),
                            "repeat_by": temparr[i].f_s_dates[0].repeat_by,
                        }
                    ],
                    "old_repeat_by": temparr[i].r_every,
                    "old_repeat_type_id": temparr[i].repeats_type_id,
                    "old_due_date": null,
                    "old_statu_dates": [{
                        "statutory_date": temparr[i].s_dates[0].statutory_date,
                        "statutory_month": temparr[i].s_dates[0].statutory_month,
                        "trigger_before_days": temparr[i].s_dates[0].trigger_before_days,
                        "repeat_by": temparr[i].s_dates[0].repeat_by,
                    }],
                })
            }
            setAssignPayload(tempArray)
        }
    }, [temparr, updatedays, updateduedate, updateevery, entityid, updaterepeats])


    useEffect(() => {
        validator.current.showMessages()
        validator1.current.showMessages()
        formValidator.current.showMessages()
    }, [])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (isAuth) {
            if (entityid != '') {
                const payload = [

                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetReviewSettingsFilters",
                            {
                                "le_id": entityid,
                                "div_id": null,
                                "cat_id": null,
                            }
                        ]
                    }
                ]
                if (entityid != 'null') {
                    if (entityid != undefined) {
                        reviewlist({
                            payload: payload,
                            paramid: paramid
                        })
                    }
                }
            }
        }
    }, [isAuth, entityid])

    useEffect(() => {
        if (list && list.unitlist && list.unitlist.length > 0) {
            let temp = []
            for (let i in list.unitlist) {
                let unitFinalCode = list.unitlist[i].u_code + ' ' + list.unitlist[i].u_name + ' ' + list.unitlist[i].address
                temp.push({
                    label: unitFinalCode,
                    value: list.unitlist[i].u_id
                })

            }
            setOptions(temp)
        }


    }, [list.unitlist])

    useEffect(() => {
        if (selected && selected.length > 0) {

            let tempIds = []
            let tempValues = []
            for (let i in selected) {
                tempIds.push(selected[i].value)
                tempValues.push(selected[i].label)

            }
            setUserDetails({
                ...userDetails,
                units: tempIds
            })
            setSelectedUnitValues(tempValues)
            setCurrentUnits(tempValues)
        }

    }, [selected])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    //  Full screen mode
    const [fullscreen, setfullscreen] = useState(false);
    const fullscreenMode = () => {
        let topBar = document.getElementById('top-nav')
        let leftbar = document.getElementsByClassName('left-sidebar');
        let leftnone = leftbar[0];
        let pageWrapper = document.getElementById('page-wrapper')
        if (fullscreen == false) {
            document.documentElement.requestFullscreen();
            topBar.style.display = 'none'
            leftnone.style.display = 'none'
            pageWrapper.style.padding = '0'
            pageWrapper.style.marginLeft = '0'
            setfullscreen(true);
        } else {
            document.exitFullscreen();
            topBar.style.display = 'block'
            leftnone.style.display = 'block'
            pageWrapper.style.marginLeft = '65px'
            pageWrapper.style.paddingTop = '70px'
            setfullscreen(false);
        }
    }

    // setAddFormSubmit(true);
    //     setUnitFromSubmit(false);
    //     if (validator.current.allValid()) {
    //         setSelectShow(true)
    //         setShow(true)
    //         const unitlist = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     "GetReviewSettingsUnitFilters",
    //                     {
    //                         "le_id": entityid,
    //                         "d_id": parseInt(userDetails.domain),
    //                         "tz_id": Number(list.time_zone_le && list.time_zone_le[0].tz_id),
    //                     }
    //                 ]
    //             }
    //         ]
    //         reviewunitlist({
    //             payload: unitlist,
    //             paramid: paramid
    //         })
    //     }
    useEffect(() => {
        if (userDetails.domain != '') {
            setAddFormSubmit(true);
            setUnitFromSubmit(false);
            const unitlist = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetReviewSettingsUnitFilters",
                        {
                            "le_id": entityid,
                            "d_id": parseInt(userDetails.domain),
                            "tz_id": Number(list.time_zone_le && list.time_zone_le[0].tz_id),
                        }
                    ]
                }
            ]
            if (validator.current.allValid()) {
                setShow(true)
                setSelectShow(true)
                reviewunitlist({
                    payload: unitlist,
                    paramid: paramid
                })
            }
        }
    }, [userDetails.domain])

    return (
        <>
            <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            <div id='page-wrapper' className="page-wrapper">
                <div className="page-titles pb-0 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Transaction</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Review Settings</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-4 text-end">
                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode rounded" >
                                <Tooltip placement="left" title="Full Screen Mode"><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card rounded-3">
                                <div className="col-md-12 p-1">
                                    <Tabs activeKey={value} type="card">
                                        <TabPane tab="Select Unit & Domain" key="1">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor=""><b>Business Group : </b></label>
                                                        </div>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Select Business Group"
                                                                    onChange={(data, value) => {
                                                                        if (data !== undefined) {
                                                                            let filteredEntity = _.filter(sessionArr, { bg_id: Number(data) })
                                                                            setFinallegalentity(filteredEntity)
                                                                            setCurrentEntity()
                                                                            setAddFormSubmit(false)
                                                                            setUnitFromSubmit(false)
                                                                            setAll([])
                                                                            setSelectShow(false)
                                                                            setShow(false)
                                                                            setUserDetails({
                                                                                ...userDetails,
                                                                                bg_id: data,
                                                                                bg_name: value.children,
                                                                                compliancefrequency: '',
                                                                                domainName: '',
                                                                                legal_entity_name: '',
                                                                                domain: ''

                                                                            })
                                                                        }
                                                                        else {
                                                                            setFinallegalentity([])
                                                                            setCurrentEntity()
                                                                            setAddFormSubmit(false)
                                                                            setUnitFromSubmit(false)
                                                                            setAll([])
                                                                            setSelectShow(false)
                                                                            setShow(false)
                                                                            setUserDetails({
                                                                                ...userDetails,
                                                                                bg_id: '',
                                                                                bg_name: "",
                                                                                compliancefrequency: '',
                                                                                domainName: '',
                                                                                legal_entity_name: '',
                                                                                domain: ''



                                                                            })
                                                                        }
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    value={userDetails.bg_id ? userDetails.bg_id : undefined}
                                                                    style={{ width: '100%', marginTop: "5px" }}
                                                                >
                                                                    {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                        if (item.bg_name !== null) {
                                                                            return (
                                                                                <Option key={item.bg_id}>
                                                                                    {item.bg_name}
                                                                                </Option>
                                                                            );
                                                                        }
                                                                    })}
                                                                </Select>
                                                                {/* {validator.current.message(
                                                                    'legal_entity_id',
                                                                    userDetails.legal_entity_id,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Required Field Missing',
                                                                        }
                                                                    })} */}
                                                            </div> :
                                                            <p> - </p>
                                                        }
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="mb-3">
                                                            <label htmlFor=""><b>Legal Entity : </b></label> <span style={{ "color": "red" }}>*</span><br />
                                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                <div className="form-group">
                                                                    <Select
                                                                        allowClear={true}
                                                                        size="default"
                                                                        placeholder="Select Legal Entity"
                                                                        onChange={(data, value) => {
                                                                            if (data !== undefined) {
                                                                                setCurrentEntity(data)
                                                                                setUserDetails({
                                                                                    ...userDetails,
                                                                                    legal_entity_id: data,
                                                                                    legal_entity_name: value.children,
                                                                                    compliancefrequency: '',
                                                                                    domain: '',
                                                                                    timezone: '',
                                                                                    units: [],
                                                                                    domainName: '',
                                                                                    dueDateList: '',
                                                                                    domain: ''
                                                                                    // bg_name: '',
                                                                                    // bg_id: ''
                                                                                })
                                                                                setAll([])
                                                                                setSelectShow(false)
                                                                                setAddFormSubmit(false)
                                                                                setShow(false)

                                                                            }
                                                                            else {
                                                                                setCurrentEntity("")
                                                                                setUserDetails({
                                                                                    ...userDetails,
                                                                                    legal_entity_id: "",
                                                                                    legal_entity_name: "",
                                                                                    compliancefrequency: '',
                                                                                    domain: '',
                                                                                    timezone: '',
                                                                                    units: [],
                                                                                    domainName: '',
                                                                                    dueDateList: '',
                                                                                    domain: ''

                                                                                    // bg_name: '',
                                                                                    // bg_id: ''
                                                                                })
                                                                                setAll([])
                                                                                setSelectShow(false)
                                                                                setAddFormSubmit(false)
                                                                                setShow(false)


                                                                            }
                                                                        }}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        value={userDetails.legal_entity_name || undefined}
                                                                        style={{ width: '100%', marginTop: "5px" }}
                                                                    >
                                                                        {finallegalEntity && finallegalEntity.length > 0 ? finallegalEntity.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.le_id}>
                                                                                    {item.le_name}
                                                                                </Option>
                                                                            );
                                                                        }) : sessionArr && sessionArr.length && sessionArr.map((item, i) => {

                                                                            return (
                                                                                <Option key={item.le_id}>
                                                                                    {item.le_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'legal_entity_id',
                                                                        userDetails.legal_entity_id,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Legal Entity Required',
                                                                            }
                                                                        })}
                                                                </div> :
                                                                <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor=""><b>Compliance Frequency : </b></label>  <span style={{ "color": "red" }}>*</span>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                                placeholder="Select Compliance Frequency"
                                                                name="compliancefrequency"
                                                                className="form-control"
                                                                id="compliancefrequency"
                                                                onChange={(data, value) => {
                                                                    if (data !== undefined) {
                                                                        console.log(data, 'datafrequ')
                                                                        setUserDetails({
                                                                            ...userDetails,
                                                                            compliancefrequency: data,
                                                                            domainName: '',
                                                                            domain: ''
                                                                        })
                                                                        setSelectShow(false)
                                                                        setAll(false)
                                                                        setShow(false)

                                                                    }
                                                                    else {
                                                                        setUserDetails({
                                                                            ...userDetails,
                                                                            compliancefrequency: '',
                                                                            domainName: ''
                                                                        })
                                                                        setSelectShow(false)
                                                                        setAll(false)
                                                                        setShow(false)

                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%', marginTop: "5px" }}
                                                                value={userDetails.compliancefrequency || undefined}
                                                            >
                                                                {list.compliance_frequency && list.compliance_frequency.length > 0 &&
                                                                    list.compliance_frequency.map((finalList) => (
                                                                        <Option key={finalList.frequency_id}>{finalList.frequency}</Option>
                                                                    ))}

                                                            </Select>
                                                            {validator.current.message(
                                                                'compliancefrequency',
                                                                userDetails.compliancefrequency,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Compliance Frequency Required',
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor=""><b>Domain : </b></label>  <span style={{ "color": "red" }}>*</span>
                                                            <Select
                                                                allowClear={true}
                                                                style={{ width: '100%', marginTop: "5px" }}
                                                                size="default"
                                                                disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                                placeholder="Select Domain"
                                                                name="domain"
                                                                className='form-control'
                                                                id="domain"
                                                                value={userDetails.domainName || undefined}
                                                                onChange={(data, value) => {
                                                                    if (data !== undefined) {
                                                                        setUserDetails({
                                                                            ...userDetails,
                                                                            domain: data,
                                                                            domainName: value.children//e.target[e.target.selectedIndex].text
                                                                        })
                                                                        setShow(false)

                                                                    }
                                                                    else {
                                                                        setUserDetails({
                                                                            ...userDetails,
                                                                            domain: "",
                                                                            domainName: ""
                                                                        })
                                                                        setShow(false)

                                                                    }
                                                                }}>
                                                                {list.domain_list && list.domain_list.length > 0 &&
                                                                    list.domain_list.map((finalList_domain) => (
                                                                        <Option key={finalList_domain.d_id}>{finalList_domain.d_name}</Option>
                                                                    ))}
                                                            </Select>
                                                            {validator.current.message(
                                                                'domain',
                                                                userDetails.domain,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Domain Required',
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor=""><b>Time Zone : </b> <span style={{ "color": "red" }}>*</span></label>
                                                            <Select
                                                                style={{ width: '100%', marginTop: "5px" }}
                                                                name="timezone"
                                                                allowClear={false}
                                                                size="default"
                                                                placeholder="Select Time Zone"
                                                                className='form-control'
                                                                id="timezone"
                                                                disabled
                                                                value={list.time_zone_le && list.time_zone_le[0].tz_name}
                                                            // onChange={(data) => {
                                                            //     console.log(data,"data");
                                                            //     setUserDetails({
                                                            //         ...userDetails,
                                                            //         timezone: data
                                                            //     })
                                                            // }}
                                                            >
                                                                {/* {list.time_zone_le && list.time_zone_le.length > 0 &&
                                                                    list.time_zone_le.map((finalList_timezone) => (
                                                                        <Option key={finalList_timezone.tz_id}>{finalList_timezone.tz_name}</Option>
                                                                    ))} */}
                                                            </Select>
                                                            {/* {validator.current.message(
                                                                'timezone',
                                                                userDetails.timezone,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Required Field Missing',
                                                                    }
                                                                })} */}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="form-group mt-2 text-center">
                                                    {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                    size='default' onClick={showDetailsOnSubmit}> Show </Button> */}
                                                    {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", align: 'center' }}
                                                        size='default' icon={<EyeOutlined />} onClick={showDetailsOnSubmit}> Show
                                                    </Button> */}
                                                </div>
                                                {/* <div class="container text-center">
                                                <button  onClick={showDetailsOnSubmit} class="cta">
                                                    <i><EyeOutlined /></i>
                                                    <span class="button-text">Show</span>
                                                </button>
                                            </div> */}
                                            </div>
                                            {show == true ?
                                                <>
                                                    <div className="col-md-12" style={{ display: selectShow ? 'block' : 'none' }} >
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label htmlFor=""><b>Vendors</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                    <Select
                                                                        name="units"
                                                                        id="units"
                                                                        className='form-control'
                                                                        onChange={(option) => HandelChange(option)}
                                                                        mode="multiple"
                                                                        value={all || undefined}
                                                                        maxTagCount='responsive'
                                                                        placeholder="Select Vendors"
                                                                    // options={unitListData}
                                                                    >
                                                                        {unitListData.length > 0 ?
                                                                            <>
                                                                                <Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                                                <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                            </>
                                                                            : ''}

                                                                        {unitListData && unitListData.length > 0 &&
                                                                            unitListData.map((finalList_unitlist) => (
                                                                                <OptGroup label={finalList_unitlist.label}>
                                                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                        <Option value={subUnitData.u_id}>{subUnitData.u_code + ' - ' + subUnitData.u_name + ' - ' + subUnitData.address}</Option>

                                                                                    ))}
                                                                                </OptGroup>
                                                                            ))}
                                                                    </Select>
                                                                    {/* <MultiSelect
                                                                options={options}
                                                                value={selected}
                                                                onChange={setSelected}
                                                                labelledBy="Select Units"
                                                                menuPlacement="top"

                                                            /> */}
                                                                    {validator1.current.message(
                                                                        'units',
                                                                        userDetails.units,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${unitFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Vendors Required',
                                                                            }
                                                                        })}

                                                                </div>
                                                                <div className='col-md-6' style={{ textAlign: 'right', marginTop: '2%' }} >
                                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                                        size='default' onClick={userDetailOnSubmit}> Next <i> <ArrowRightOutlined /></i>
                                                                    </Button>
                                                                    {/* <button style={{ float: "right" }} className="usrbutton button--anthe" onClick={userDetailOnSubmit}><span class="text-white fs-3 fw-bold">Next</span></button> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </>
                                                : ""}
                                        </TabPane>
                                        <TabPane tab="Date Settings" disabled={selectUnit === true ? false : true} key="2">
                                            <div className='row'>
                                                <div className="col-md-5">
                                                    <div className="">
                                                        <p style={{ "fontWeight": "bold" }}>{(userDetails.legal_entity_name) ? userDetails.legal_entity_name : localStorage.getItem('SelectedEntity')}&nbsp;<span>{"-"}</span>&nbsp;{userDetails.compliancefrequency == 3 ? 'Review' : 'Flexi Review'}<span>{"-"}</span>&nbsp;{userDetails.domainName}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label><b>Domain Name :</b><span className='mx-2'>{userDetails.domainName}</span></label>
                                                </div>
                                                <div className="col-md-3">
                                                    <label><b>Timeline :</b><span className='mx-2'>{list.timeline}</span></label>
                                                </div>



                                            </div>

                                            <div className="col-md-12 mt-1">
                                                <div className="row">
                                                    <Collapse className='stat-collapse cric' defaultActiveKey={['0']}>
                                                        {temp.tempData && temp.tempData.length > 0 ?
                                                            temp.tempData.map((finalList) => (

                                                                <Panel header={finalList.parentData} extra={<div style={{ width: '160px', textAlign: 'left' }}><span id={'parentid-' + finalList.index} style={{ display: 'none' }}>{finalList.index}</span><span>Total Tasks : {finalList.child.length}</span></div>} key={finalList.index}>
                                                                    <Table
                                                                        className={" review_settings " + localStorage.getItem("currentTheme")}
                                                                        id='review-setting-table'
                                                                        columns={columns}
                                                                        dataSource={finalList.child}
                                                                        bordered
                                                                        scroll={{ x: 1100 }}
                                                                        pagination={false}
                                                                        rowSelection={{
                                                                            columnWidth: '20px',
                                                                            columnTitle: <input type="checkbox" name="allchecked" id={finalList.index} className={'tempclass checkclass' + finalList.index}
                                                                                onClick={(e) => {
                                                                                    let checked = e.target.checked;
                                                                                    addAllSelectedDataValues(
                                                                                        checked, finalList.index, finalList.parentData, finalList.child
                                                                                    );
                                                                                }}
                                                                            />,
                                                                            ...rowSelection
                                                                        }}
                                                                        size={'small'}
                                                                    />

                                                                </Panel>


                                                            )) : <div style={{ textAlign: 'center' }}>No Records Found</div>}
                                                    </Collapse>

                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    <div className='col-md-3'><label>Selected Compliance: <span>{temparr.length}</span></label></div>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4 mt-1'  >
                                                        {/* <button className='btn btn-info' onClick={previous}>Previous </button> */}
                                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", }}
                                                            icon={<ArrowLeftOutlined />} size='default' onClick={previous}>
                                                            Previous
                                                        </Button>
                                                        {/* <button style={{ float: "left", marginTop: '5px' }} className="usrbutton button--anthe prev" onClick={previous}><span class="text-white fs-3 fw-bold">Previous</span></button> */}
                                                    </div>
                                                    <div className='col-md-4 mt-1 text-center' >
                                                        {/* <button className='btn btn-info' onClick={saveRecords}>Submit</button> */}
                                                        {temp.tempData && temp.tempData.length > 0 ?
                                                            <Button type="primary" shape="round"
                                                                style={{ background: "#198754", borderColor: "#198754" }}
                                                                icon={<PlayCircleOutlined />} size='default' onClick={saveRecords} >
                                                                Submit
                                                            </Button>
                                                            // <div class="button2" id={localStorage.getItem('currentTheme')} onClick={saveRecords}>
                                                            //     <span></span>
                                                            //     <span></span>
                                                            //     <span></span>
                                                            //     <span></span>
                                                            //     Submit
                                                            // </div>
                                                            : false}
                                                    </div>
                                                </div>
                                            </div>

                                        </TabPane>
                                    </Tabs>

                                </div>
                            </div>
                        </div>
                        <Modal title="Applicable Vendors" visible={isModalVisible} onCancel={handleCancel} footer={null} className={"remarksClass add-service-prv " + localStorage.getItem('currentTheme')}>
                            <ul>
                                {currentUnits.map((childdetails, index) => {
                                    return <li key={index}>{childdetails[0].u_code + "-" + childdetails[0].u_name + "-" + childdetails[0].address}</li>

                                })}
                            </ul>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(mapStateToProps, {
    reviewlist,
    reviewunitlist,
    reviewshowsettinglist,
    reviewsavecompliance
})(Reviewsetting);

/*   {
        title: '',
        dataIndex: 'parentId',
        key: 'parentId',
        width: '35px',
        align: 'center',
        render: (text, record) => {
            console.log(record, "record12345");
            return (
                <Fragment>
                    <input type="checkbox"
                        name={'checked' + record.parentId}
                        className={'checkclasschild' + record.parentId}
                        onClick={(e) => {
                            console.log(e, 'e.target.checked')
                            let checked = e.target.checked;
                            addSelectedDataValues(
                                checked,
                                record.comp_id,
                                record.childId,
                                record.parentId
                            );
                        }}
                    />
                </Fragment>
            )
        }
    },



       const addAllSelectedDataValues = (checked, u_id) => {
    console.log(checked, "checked");
    let allArray = [];
    var ele = document.getElementsByName('checked');
    if (checked === true) {
      setCheckedRow(true)
      if (list && list.length > 0) {
        let temp = list.filter((item) => {
          return item.is_locked == false
        })
        let temp1 = []
        for (let i in list) {
          temp1.push(list[i])
        }
        for (var i = 0; i < ele.length; i++) {
          if (ele[i].type === 'checkbox')
            ele[i].checked = true;
        }
        setSelectedRows(temp1)
      }
    } else {
      setCheckedRow(false)
      setSelectedRows([])
      for (var j = 0; j < ele.length; j++) {
        if (ele[j].type === 'checkbox')
          ele[j].checked = false;
      }
    }
  };

  const addSelectedDataValues = (checked, u_id, act_name) => {
    var ele = document.getElementsByName('allchecked');
    let array
    let tempArray = selectedRows
    if (checkedRow === true) {
      if (checked === true) {
        array = _.find(list, { u_id: u_id });
        tempArray.push(array)
      } else {
        for (let i in tempArray) {
          if (tempArray[i].u_id === u_id) {
            tempArray.splice(i, 1)
          }
          if (list.length !== selectedRows.length) {
            console.log("Nithin");
            console.log(list.length, "list.length");
            console.log(selectedRows.length, "selectedRows.length");
            setCheckedRow(false)
            for (var j = 0; j < ele.length; j++) {
              if (ele[j].type === 'checkbox')
                ele[j].checked = false;
            }
          }
        }
      }
      setSelectedRows([...tempArray])
    }
    else {
      if (checked === true) {
        array = _.find(list, { u_id: u_id });
        tempArray.push(array)
        if (list.length === selectedRows.length) {
          console.log("Nithuuuuuu");
          console.log(list.length, "list.length");
          console.log(selectedRows.length, "selectedRows.length");
          setCheckedRow(true)
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].type === 'checkbox')
              ele[i].checked = true;
          }
        }
      } else {
        for (let i in tempArray) {
          if (tempArray[i].u_id === u_id) {
            tempArray.splice(i, 1)
          }


        }
      }
      setSelectedRows([...tempArray])
    }
  }   */