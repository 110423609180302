import React from 'react'
import { InfoCircleOutlined, PlayCircleOutlined, ExclamationCircleTwoTone, FullscreenOutlined, UpOutlined, EyeOutlined, ExportOutlined, FilterOutlined } from '@ant-design/icons';
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Pagination } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useRef, useEffect, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { RiskReportData, getRiskReportData, getRiskExportData, Downloadfile, downloadfiles, downloadDocument } from '../../../Store/Action/Report/RiskReport';
import { AUDIT_TRAIL_REPORT_EXPORT_RESET } from './../../../Store/types/index'
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import ScrollToTop from "react-scroll-to-top";

const mapStateToProps = (state) => ({
    allData: state.RiskReport.riskReport.data,
    tableData: state.RiskReport.riskReport.getTableData,
    downloadpath: state.RiskReport.riskReport.downpath,
    downloadFile: state.RiskReport.riskReport.downloadFile,
})

const RiskReport = ({
    RiskReportData,
    allData,
    tableData,
    getRiskReportData,
    getRiskExportData,
    Downloadfile,
    downloadfiles,
    downloadDocument,
    downloadFile
}) => {

    const _ = require("lodash");
    const dispatch = useDispatch();
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const paramid = location.pathname;
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [titledata, Settitledata] = useState()
    const [key, setKey] = useState("1")
    let [index, setIndex] = useState(0)

    const [consolidateddata, setConsolidatedDate] = useState({
        country: "",
        category: "",
        user_type: "",
        legel_entity: "",
        unit: '',
        user: "",
        domain: "",
        act: "",
        from_date: "",
        task_category: "",
        compliance_task: "",
        to_date: "",
        task_sub_category: "",
        compliance_frequency: "",
        compliance_task_status: "",
        division: "",
        domainName: '',
        countryName: '',
        unit_name: '',
        user_name: '',
        statutory_mapping: '',
        division_name: '',
        compliance_frequency_name: '',
        compliance_task_status_name: '',
        category_name: '',
        user_type_name: '',
        task_status: 'All Exposed Risk',
        legal_entity_name: ''

    })
    console.log(consolidateddata, 'consolidateddata');
    const validator = useRef(new SimpleReactValidator());
    const [exportButton, setExportButton] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTaskHistoryModalOpen, setIsTaskHistoryModalOpen] = useState(false);
    const [domainList, setDomainList] = useState([])
    const [divisionList, setDivisionList] = useState([])
    const [unitCodeLabelList, setUnitCodeLabelList] = useState([])
    const [unitLegalEntity, setUnitLegalEntity] = useState([])
    const [complianceUser, setComplianceUser] = useState([])
    const [complianceUserType, setComplianceUserType] = useState([])
    const [complianceTaskStatus, setComplianceTaskStatus] = useState([])
    const [complianceFrequencyList, setComplianceFrequencyList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [actLegalEntity, setActLegalEntity] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [actName, setActName] = useState([])
    const [actNameFilteredState, setActNameFilteredState] = useState([])
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const [exitCollapse, setExitCollapse] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const SelectedEntity = localStorage.getItem('SelectedEntity')
    const [businessGroup, setBusinessGroup] = useState({
        bg_id: '',
        bg_name: ''
    })
    const [checkboxValue, setCheckBoxValue] = useState([])
    const [count, setCount] = useState('')
    const [pageSize, setPageSize] = useState(25)
    const [current, setCurrent] = useState(1)
    const [pageState, setpageState] = useState(false);
    const [dataSource, setDataSource] = useState([])
    const [taskArray, setTaskArray] = useState([])

    const { Panel } = Collapse;
    const { Option } = Select;

    const [fullscreen, setfullscreen] = useState(false);
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    const onshow = () => {
        setAddFormSubmit(true)
        setFilteredArrayState([])
        setFilterModalVisible(false);
        if (validator.current.allValid()) {
        setExitCollapse(true);
        setFilterTaskAcc(false);

            setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetRiskReportData",
                        {
                            "country_id": Number(consolidateddata.country),
                            "business_group_id": Number(businessGroup.bg_id) ? Number(businessGroup.bg_id) : 1,
                            "legal_entity_id": consolidateddata.legel_entity,
                            "domain_id": Number(consolidateddata.domain),
                            "division_id": Number(consolidateddata.division),
                            "category_id": Number(consolidateddata.category) ? Number(consolidateddata.category) : 0,
                            "unit_id": Number(consolidateddata.unit) ? Number(consolidateddata.unit) : 0,
                            "parent_id": Number(consolidateddata.act) ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? consolidateddata.compliance_task : null,
                            "task_status": consolidateddata.task_status,
                            "csv": false,
                            "from_count": 0,
                            "page_count": 25,
                            "list_check": null
                        }
                    ]
                }
            ]
            console.log(payload, 'payload');
            getRiskReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const columns = [
        {
            title: 'Start Date',
            // ellipsis: true,
            width: '70px',
            // dataIndex: 'start_date',
            // key: 'start_date',
            render: (record) => {
                console.log(record, 'record');
                return <p style={{ textAlign: 'center' }}>{record.start_date}</p>
            }
        },
        {
            title: 'Due Date',
            // ellipsis: true,
            // selector: (row) => row.compliance_task,
            width: '70px',
            // dataIndex: 'due_date',
            // key: 'due_date',
            render: (record) => {
                console.log(record, 'record');
                return <p style={{ textAlign: 'center' }}>{record.due_date}</p>
            }
        },
        {
            title: "Assignee",
            // ellipsis: true,
            width: '60px',
            align: 'center',
            // dataIndex: 'assignee',
            // key: 'assignee',
            render: (record) => {
                console.log(record, 'record');
                return <p style={{ textAlign: 'center' }}>{record.assignee}</p>
            }
        },
        {
            title: "Concurr",
            width: '50px',
            // ellipsis: true,
            // dataIndex: 'concurr',
            // key: 'concurr',
            render: (record) => {
                console.log(record, 'record');
                return <p style={{ textAlign: 'center' }}>{record.concurr ? record.concurr : '-'}</p>
            }
        },
        {
            title: "Approver",
            width: '50px',
            // ellipsis: true,
            // dataIndex: 'approver',
            // key: 'approver',
            render: (record) => {
                console.log(record, 'record');
                return <p style={{ textAlign: 'center' }}>{record.approver ? record.approver : '-'}</p>
            }
        },
        {
            title: "Remarks",
            width: '50px',
            // ellipsis: true,
            // dataIndex: 'remarks',
            // key: 'remarks',
            render: (record) => {
                console.log(record, 'record');
                return <p style={{ textAlign: 'center' }}>{record.remarks}</p>
            }
        },
    ];


    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };

    const handleOk = () => {
        setAddFormSubmit(true)
        setExportButton(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetRiskReportData",
                        {
                            "country_id": Number(consolidateddata.country),
                            "business_group_id": Number(businessGroup.bg_id),
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidateddata.domain),
                            "division_id": Number(consolidateddata.division),
                            "category_id": Number(consolidateddata.category) ? Number(consolidateddata.category) : 0,
                            "unit_id": Number(consolidateddata.unit) ? Number(consolidateddata.unit) : 0,
                            "parent_id": Number(consolidateddata.act) ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? consolidateddata.compliance_task : null,
                            "task_status": consolidateddata.task_status ? consolidateddata.task_status : 'All Exposed Risk',
                            "csv": true,
                            "from_count": 0,
                            "page_count": 25,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            getRiskExportData({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false);
    };

    const handleTaskHistoryOk = () => {
        setIsTaskHistoryModalOpen(false);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showTaskHistoryModal = () => {
        setIsTaskHistoryModalOpen(true);
    };

    const handleTaskHistoryCancel = () => {
        setIsTaskHistoryModalOpen(false);
    };


    const onChange = (key) => {
        console.log(key);
    };

    const checkBoxOnChange = (e) => {
        let temp = checkboxValue
        let tempIds = []
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([])
        }
    }

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (tableData && tableData.total_count > 0) {
            setCount(tableData.total_count)
        }
    }, [tableData])

    // useEffect(() => {
    //     if (downloadpath) {
    //         const path = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     "GetStatus",
    //                     {
    //                         "legal_entity_id": entityid,
    //                         "csv_name": downloadpath.csv_name
    //                     }
    //                 ]
    //             }
    //         ]
    //         downloadfiles({
    //             payload: path,
    //             paramid: paramid
    //         })
    //     }
    // }, [downloadpath])

    useEffect(() => {
        if (tableData && tableData.risk_report && tableData.risk_report.length > 0) {
            let uniqueObjArray = [...new Map(tableData.risk_report && tableData.risk_report.length && tableData.risk_report.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(tableData.risk_report, { unit_id: uniqueIds[i] })
                tempArr.push(filteredArr)
                setFilteredArray([...filteredArray, tempArr])
            }
        }
    }, [tableData.risk_report])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (tableData && tableData.risk_report && tableData.risk_report.length && tableData.risk_report) {
            const ab = tableData && tableData.risk_report && tableData.risk_report.length && tableData.risk_report.map(function (item) {
                if ((array.indexOf(item.statutory_mapping) < 0))
                    array.push(item.statutory_mapping)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    tableData && tableData.risk_report && tableData.risk_report.length && tableData.risk_report.map((child, childIndex) => {
                        if (data === child.statutory_mapping) {
                            entityArray = {
                                "Criticality": child.Criticality,
                                "admin_incharge": child.admin_incharge,
                                "approved_on": child.approved_on,
                                "approver_name": child.approver_name,
                                "assigned_on": child.assigned_on,
                                "assignee_name": child.assignee_name,
                                "category_name": child.category_name,
                                "comp_remarks": child.comp_remarks,
                                "compliance_description": child.compliance_description,
                                "compliance_task": child.compliance_task,
                                "concurred_on": child.concurred_on,
                                "concurrer_name": child.concurrer_name,
                                "division_name": child.division_name,
                                "doc_ref_num": child.doc_ref_num,
                                "document_name": child.document_name,
                                "due_date": child.due_date,
                                "extend_due_date": child.extend_due_date,
                                "frequency_name": child.frequency_name,
                                "logo_url": child.logo_url,
                                "penal_consequences": child.penal_consequences,
                                "start_date": child.start_date,
                                "statutory_mapping": child.statutory_mapping,
                                "task_status": child.task_status,
                                "tz_name": child.tz_name,
                                "unit_address": child.unit_address,
                                "unit_id": child.unit_id,
                                "unit_name": child.unit_name,
                                "url": child.url,
                            }
                            temp.push(entityArray)
                        }
                    })
                }
                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

    }, [tableData && tableData.risk_report && tableData.risk_report.length && tableData.risk_report])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setFilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        if (downloadFile && downloadFile.link) {
            window.open('https://13.234.100.64/' + downloadFile.link);
            dispatch({
                type: AUDIT_TRAIL_REPORT_EXPORT_RESET
            });
        }

    }, [downloadFile])


    useEffect(() => {
        if (SelectedEntity != 'All Legal Entity') {
            let businessName = _.filter(sessionArr, { le_name: SelectedEntity })
            setBusinessGroup({
                ...businessGroup,
                bg_id: businessName[0].bg_id,
                bg_name: businessName[0].bg_name
            })
            setConsolidatedDate({
                ...consolidateddata,
                country: sessionArr[0].c_id
            })
        }
    }, [SelectedEntity])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            setConsolidatedDate({
                ...consolidateddata,
                legel_entity: localStorage.getItem('SelectedEntityid'),
                country: sessionArr[0].c_id
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
        else {
            setCurrentEntity(consolidateddata.legel_entity)
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetRiskReportFilters",
                        {
                            "country_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? Number(consolidateddata.country) : sessionArr[0].c_id,
                            "business_group_id": Number(businessGroup.bg_id),
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            if (entityid != 'null') {
                RiskReportData({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [isAuth, authtoken, paramid, entityid])

    useEffect(() => {

        if (pageState == true) {

            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetRiskReportData",
                        {
                            "country_id": Number(consolidateddata.country),
                            "business_group_id": Number(businessGroup.bg_id),
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidateddata.domain),
                            "division_id": Number(consolidateddata.division),
                            "category_id": Number(consolidateddata.category) ? Number(consolidateddata.category) : 0,
                            "unit_id": Number(consolidateddata.unit) ? Number(consolidateddata.unit) : 0,
                            "parent_id": Number(consolidateddata.act) ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? consolidateddata.compliance_task : null,
                            "task_status": consolidateddata.task_status,
                            "csv": false,
                            "from_count": Math.max(((current - 1) * pageSize), 0),
                            "page_count": current != 0 ? current * pageSize : pageSize,
                            "list_check": null
                        }
                    ]
                }
            ]
            getRiskReportData({
                payload: payload,
                paramid: paramid
            })
        }

    }, [current, pageSize, pageState])

    useEffect(() => {
        if (pageState === true) {
            setIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    useEffect(() => {
        if (allData) {
            setActLegalEntity(allData && allData.act_legal_entity)
            setCategoryList(allData && allData.categories)
            setComplianceFrequencyList(allData && allData.compliance_frequency_list)
            setComplianceTaskStatus(allData && allData.compliance_task_status)
            setUnitLegalEntity(allData && allData.units_list)
            setUnitCodeLabelList(allData && allData.unit_code_label_list)
            setDivisionList(allData && allData.divisions)
            setDomainList(allData && allData.domains)
            // setComplianceUserType(allData && allData.compliance_user_type)
            // setComplianceUser(allData && allData.compliance_users)
        }

    }, [allData])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <div id='page-wrapper' className="page-wrapper">
            <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            <div className="page-titles pb-0 pt-1">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>Compliance Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span>Risk Report</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-6 text-end">
                        <Button
                            className="mx-1"
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button>
                        <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                            <Tooltip placement="left" title="Full Screen Mode"><FullscreenOutlined /></Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <Modal title="Select Fields" className={'add-service-prv ' + localStorage.getItem('currentTheme')} visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100}>
                <div className='row'>
                    <div className='col-lg-3'><input id="select_all" width="100%" type="checkbox" autocomplete="off"
                        checked={checkboxValue.length === 34 ? true : false}
                        onClick={(e) => {
                            let checked = e.target.checked
                            selectAllCheckBox(checked)
                        }} /> Select All</div>
                    <div className='col-lg-3'><input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /> Legal Entity</div>
                    <div className='col-lg-3'><input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code</div>
                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='3' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Vendor Name</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_8" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 1 </div>
                    <div className='col-lg-3'><input id="column_9" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 2 </div>
                    <div className='col-lg-3'><input id="column_10" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 3 </div>
                    <div className='col-lg-3'><input id="column_29" name='chk' type="checkbox" value='7' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 4</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_32" name='chk' type="checkbox" value='8' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Location</div>
                    <div className='col-lg-3'><input id="column_3" name='chk' type="checkbox" value='9' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Act/Rules </div>
                    <div className='col-lg-3'><input id="column_4" name='chk' type="checkbox" value='10' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Compliance Task </div>
                    <div className='col-lg-3'><input id="column_5" name='chk' type="checkbox" value='11' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Frequency</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_6" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> Periodicity</div>
                    <div className='col-lg-3'><input id="column_11" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Criticality</div>
                    <div className='col-lg-3'><input id="column_12" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> Division</div>
                    <div className='col-lg-3'><input id="column_13" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Category </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Assigned by</div>
                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Assigned To</div>
                    <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> Assigned Date</div>
                    <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Assignee</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_18" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> Completed on</div>
                    <div className='col-lg-3'><input id="column_19" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> Concurrer</div>
                    <div className='col-lg-3'><input id="column_20" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> Concurred on </div>
                    <div className='col-lg-3'><input id="column_21" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> Approver </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_22" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> Approved on </div>
                    <div className='col-lg-3'><input id="column_23" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> Start Date </div>
                    <div className='col-lg-3'><input id="column_24" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> Due Date </div>
                    <div className='col-lg-3'><input id="column_25" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} /> Extend Due Date</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_26" name='chk' type="checkbox" value='28' onChange={(e) => checkBoxOnChange(e)} /> Task Completion Date / Document Issued Date</div>
                    <div className='col-lg-3'><input id="column_27" name='chk' type="checkbox" value='29' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Validate Date</div>
                    <div className='col-lg-3'><input id="column_28" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} /> Compliance Task Status </div>
                    <div className='col-lg-3'><input id="column_28" name='chk' type="checkbox" value='31' onChange={(e) => checkBoxOnChange(e)} /> Duration </div>

                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='32' onChange={(e) => checkBoxOnChange(e)} /> Document Reference Number</div>
                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='33' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} />  Penal Consequences</div>
                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='32' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Remarks</div>

                </div>
                <div className="form-actions text-center popupbtncolour">
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>
                </div>

            </Modal>
            <Modal title="Task History" className={'add-service-prv ' + localStorage.getItem('currentTheme')} visible={isTaskHistoryModalOpen} onOk={handleTaskHistoryOk} onCancel={handleTaskHistoryCancel} width={100} footer={null}>
                <Table
                    className='userprivclass'
                    columns={columns}
                    dataSource={dataSource}
                    bordered
                    // scroll={{ x: 1500 }}
                    pagination={false} />
            </Modal>
            <div className="container-fluid pt-0">
                <div className="row">
                    <div className="col-lg-12">
                        <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory'} style={{ position: 'relative', padding: "10px 3px" }}>
                            <Collapse className='report-collapse' style={{ display: exitCollapse ? "none" : "block" }} defaultActiveKey={["1"]} onChange={onChange} >
                                <Panel header=" Risk Report" key={key}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <label htmlFor=""><b>Country : </b><span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Country"
                                                                onChange={(value, data) => {
                                                                    setConsolidatedDate({
                                                                        ...consolidateddata,
                                                                        country: value,
                                                                        countryName: data.children,
                                                                        category: "",
                                                                        user_type: "",
                                                                        legel_entity: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "",
                                                                        division: "",
                                                                        domainName: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        statutory_mapping: '',
                                                                        division_name: '',
                                                                        compliance_frequency_name: '',
                                                                        compliance_task_status_name: '',
                                                                        category_name: '',
                                                                        user_type_name: '',
                                                                        task_status: 'All Exposed Risk',
                                                                        legal_entity_name: ''
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '2px' }}

                                                                value={consolidateddata.countryName || undefined}
                                                            >
                                                                {country_info && country_info.length > 0 && country_info.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {/* {validator.current.message(
                                                                'legalentity',
                                                                consolidateddata.country,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Required',
                                                                    }
                                                                })} */}
                                                        </div> :
                                                        <p>{sessionArr[0].c_name}</p>
                                                    }
                                                </div>
                                                {validator.current.message(
                                                    'country',
                                                    consolidateddata.country,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Country Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <b><label>Legal Entity : <span style={{ color: "red" }}>*</span></label></b>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Legal Entity"
                                                            onChange={(value, data) => {
                                                                setCurrentEntity(value)
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    legel_entity: value,
                                                                    legal_entity_name: data.children,
                                                                    category: "",
                                                                    user_type: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    division: "",
                                                                    domainName: '',
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    statutory_mapping: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: '',
                                                                    compliance_task_status_name: '',
                                                                    category_name: '',
                                                                    user_type_name: '',
                                                                    task_status: 'All Exposed Risk',
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '2px' }}

                                                            value={consolidateddata.legal_entity_name || undefined}
                                                            disabled={consolidateddata.country === '' ? true : false}

                                                        >
                                                            {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legalentity',
                                                            consolidateddata.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                domain: value,
                                                                domainName: data.children,
                                                                category: "",
                                                                user_type: "",
                                                                unit: '',
                                                                user: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "",
                                                                division: "",
                                                                unit_name: '',
                                                                user_name: '',
                                                                statutory_mapping: '',
                                                                division_name: '',
                                                                compliance_frequency_name: '',
                                                                compliance_task_status_name: '',
                                                                category_name: '',
                                                                user_type_name: '',
                                                                task_status: 'All Exposed Risk',
                                                            })
                                                        }}
                                                        placeholder="Enter Domain"
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={consolidateddata.domainName || undefined}
                                                        disabled={consolidateddata.legel_entity == '' ? true : false}
                                                    >
                                                        {domainList && domainList.length > 0 && domainList.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                {validator.current.message(
                                                    'domain',
                                                    consolidateddata.domain,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ marginTop: '5px' }}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label><b>Business Group:</b></label><br />
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Business Group"
                                                            onChange={(value, data) => {
                                                                setBusinessGroup({
                                                                    ...businessGroup,
                                                                    bg_id: value,
                                                                    bg_name: data.children
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={businessGroup.bg_name || undefined}
                                                            disabled={consolidateddata.country === '' ? true : false}
                                                            style={{ width: '100%', marginTop: '2px' }}

                                                        >
                                                            {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                if (item.bg_id != null) {
                                                                    return (
                                                                        <Option key={item.bg_id}>
                                                                            {item.bg_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            })}
                                                        </Select>
                                                    </div> :
                                                    <p>{businessGroup.bg_name ? businessGroup.bg_name : '-'}</p>
                                                }
                                            </div>
                                            <div className="col-md-4">
                                                <label><b>Category : </b></label><br />
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                category: value,
                                                                category_name: data.children
                                                            })
                                                        }}
                                                        placeholder="Enter Category"
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={consolidateddata.category_name || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                            return (
                                                                <Option key={item.category_id}>
                                                                    {item.category_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor=""><b>Compliance Task Status : </b></label>
                                                <div className="form-group">
                                                    <Select allowClear={true} placeholder='Enter Compliance Task Status'
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                task_status: value,
                                                                compliance_task_status_name: data.children
                                                            })
                                                        }}
                                                        // placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={consolidateddata.task_status || undefined}
                                                        disabled={consolidateddata.legel_entity == '' ? true : false}
                                                    >
                                                        <Option key='All Exposed Risk'>All Exposed Risk</Option>
                                                        {complianceTaskStatus && complianceTaskStatus.length > 0 && complianceTaskStatus.map((item, i) => {
                                                            return (
                                                                <Option key={item.task_status}>
                                                                    {item.task_status}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ marginTop: '5px' }}>
                                        <div className="row">

                                            <div className='col-md-4'>
                                                <label><b>Vendor : </b></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                unit: value,
                                                                unit_name: data.children
                                                            })
                                                        }}
                                                        placeholder="Enter Vendor"
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={consolidateddata.unit_name || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {unitLegalEntity && unitLegalEntity.length && unitLegalEntity.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {`${item.unit_code}-${item.unit_name}`}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <label htmlFor=""><b>Division : </b></label>
                                                <div className="form-group">

                                                    <Select allowClear={true}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                division: value,
                                                                division_name: data.children
                                                            })
                                                            let filteredCategory = _.filter(allData && allData.categories, { division_id: Number(value) })
                                                            setCategoryList(filteredCategory)
                                                            let filteredUnit = _.filter(allData && allData.units_list, { division_id: Number(value) })
                                                            setUnitLegalEntity(filteredUnit)
                                                        }}
                                                        placeholder="Enter Division"
                                                        style={{ width: '100%', marginTop: '2px' }} s
                                                        value={consolidateddata.division_name || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {divisionList && divisionList.length > 0 && divisionList.map((item, i) => {
                                                            return (
                                                                <Option key={item.division_id}>
                                                                    {item.division_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <label htmlFor=""><b>Compliance Task : </b></label>
                                                <div className="form-group">
                                                    <Input allowClear={true} placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '2px' }}

                                                        onChange={(e) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                compliance_task: e.target.value
                                                            })
                                                        }}
                                                        value={consolidateddata.compliance_task}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ marginTop: '5px' }}>
                                        <div className="row">

                                            <div className='col-md-4'>
                                                <label><b>Act : </b></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                act: value,
                                                                statutory_mapping: data.children
                                                            })
                                                        }}
                                                        placeholder="Enter Act"
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={consolidateddata.statutory_mapping || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {actLegalEntity && actLegalEntity.length > 0 && actLegalEntity.map((item, i) => {
                                                            return (
                                                                <Option key={item.parent_id}>
                                                                    {item.statutory_mapping}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            {/* <div className='col-md-4'></div>
                                            <div className='col-md-4'></div> */}
                                            <div className='col-md-4 popupbtncolour' style={{ marginLeft: '37%' }}>
                                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px" }}
                                                    icon={<EyeOutlined />} onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal}
                                                    icon={<ExportOutlined />}   >
                                                    Export
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Modal
                                onCancel={setcancelFilter}
                                footer={false}
                                title="Task Category Report"
                                className={
                                    "add-service-prv cons-report " +
                                    localStorage.getItem("currentTheme")
                                }
                                visible={filterModalVisible}
                            >
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-4">
                                            <label htmlFor=""><b>Country : </b><span style={{ color: "red" }}>*</span></label>
                                            <div className="form-group">
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Country"
                                                            onChange={(value, data) => {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    country: value,
                                                                    countryName: data.children,
                                                                    category: "",
                                                                    user_type: "",
                                                                    legel_entity: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    division: "",
                                                                    domainName: '',
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    statutory_mapping: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: '',
                                                                    compliance_task_status_name: '',
                                                                    category_name: '',
                                                                    user_type_name: '',
                                                                    task_status: 'All Exposed Risk',
                                                                    legal_entity_name: ''
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '2px' }}

                                                            value={consolidateddata.countryName || undefined}
                                                        >
                                                            {country_info && country_info.length > 0 && country_info.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {/* {validator.current.message(
                                            'legalentity',
                                            consolidateddata.country,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Country Required',
                                                }
                                            })} */}
                                                    </div> :
                                                    <p>{sessionArr[0].c_name}</p>
                                                }
                                            </div>
                                            {validator.current.message(
                                                'country',
                                                consolidateddata.country,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Country Required',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-md-4'>
                                            <b><label>Legal Entity : <span style={{ color: "red" }}>*</span></label></b>
                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Legal Entity"
                                                        onChange={(value, data) => {
                                                            setCurrentEntity(value)
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                legel_entity: value,
                                                                legal_entity_name: data.children,
                                                                category: "",
                                                                user_type: "",
                                                                unit: '',
                                                                user: "",
                                                                domain: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "",
                                                                division: "",
                                                                domainName: '',
                                                                unit_name: '',
                                                                user_name: '',
                                                                statutory_mapping: '',
                                                                division_name: '',
                                                                compliance_frequency_name: '',
                                                                compliance_task_status_name: '',
                                                                category_name: '',
                                                                user_type_name: '',
                                                                task_status: 'All Exposed Risk',
                                                            })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        //value={Settings.le_id}
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={consolidateddata.legal_entity_name || undefined}
                                                        disabled={consolidateddata.country === '' ? true : false}

                                                    >
                                                        {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'legalentity',
                                                        consolidateddata.legel_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Required',
                                                            }
                                                        })}
                                                </div> :
                                                <p>{localStorage.getItem('SelectedEntity')}</p>
                                            }
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                                            <div className="form-group">
                                                <Select
                                                    allowClear={true}
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            domain: value,
                                                            domainName: data.children,
                                                            category: "",
                                                            user_type: "",
                                                            unit: '',
                                                            user: "",
                                                            act: "",
                                                            from_date: "",
                                                            task_category: "",
                                                            compliance_task: "",
                                                            to_date: "",
                                                            task_sub_category: "",
                                                            compliance_frequency: "",
                                                            compliance_task_status: "",
                                                            division: "",
                                                            unit_name: '',
                                                            user_name: '',
                                                            statutory_mapping: '',
                                                            division_name: '',
                                                            compliance_frequency_name: '',
                                                            compliance_task_status_name: '',
                                                            category_name: '',
                                                            user_type_name: '',
                                                            task_status: 'All Exposed Risk',
                                                        })
                                                    }}
                                                    placeholder="Enter Domain"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                    value={consolidateddata.domainName || undefined}
                                                    disabled={consolidateddata.legel_entity == '' ? true : false}
                                                >
                                                    {domainList && domainList.length > 0 && domainList.map((item, i) => {
                                                        return (
                                                            <Option key={item.d_id}>
                                                                {item.d_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            {validator.current.message(
                                                'domain',
                                                consolidateddata.domain,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Domain Required',
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ marginTop: '5px' }}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label><b>Business Group:</b></label><br />
                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Business Group"
                                                        onChange={(value, data) => {
                                                            setBusinessGroup({
                                                                ...businessGroup,
                                                                bg_id: value,
                                                                bg_name: data.children
                                                            })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={businessGroup.bg_name || undefined}
                                                        disabled={consolidateddata.country === '' ? true : false}
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                    >
                                                        {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                            if (item.bg_id != null) {
                                                                return (
                                                                    <Option key={item.bg_id}>
                                                                        {item.bg_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        })}
                                                    </Select>
                                                </div> :
                                                <p>{businessGroup.bg_name ? businessGroup.bg_name : '-'}</p>
                                            }
                                        </div>
                                        <div className="col-md-4">
                                            <label><b>Category : </b></label><br />
                                            <div className="form-group">
                                                <Select allowClear={true}
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            category: value,
                                                            category_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Category"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                    value={consolidateddata.category_name || undefined}
                                                    disabled={consolidateddata.domain == '' ? true : false}
                                                >
                                                    {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                        return (
                                                            <Option key={item.category_id}>
                                                                {item.category_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor=""><b>Compliance Task Status : </b></label>
                                            <div className="form-group">
                                                <Select allowClear={true} placeholder='Enter Compliance Task Status'
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            task_status: value,
                                                            compliance_task_status_name: data.children
                                                        })
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                    value={consolidateddata.task_status || undefined}
                                                    disabled={consolidateddata.legel_entity == '' ? true : false}
                                                >
                                                    <Option key='All Exposed Risk'>All Exposed Risk</Option>
                                                    {complianceTaskStatus && complianceTaskStatus.length > 0 && complianceTaskStatus.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_status}>
                                                                {item.task_status}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ marginTop: '5px' }}>
                                    <div className="row">

                                        <div className='col-md-4'>
                                            <label><b>Vendor : </b></label>
                                            <div className="form-group">
                                                <Select
                                                    allowClear={true}
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            unit: value,
                                                            unit_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Vendor"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                    value={consolidateddata.unit_name || undefined}
                                                    disabled={consolidateddata.domain == '' ? true : false}
                                                >
                                                    {unitLegalEntity && unitLegalEntity.length && unitLegalEntity.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {`${item.unit_code}-${item.unit_name}`}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <label htmlFor=""><b>Division : </b></label>
                                            <div className="form-group">

                                                <Select allowClear={true}
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            division: value,
                                                            division_name: data.children
                                                        })
                                                        let filteredCategory = _.filter(allData && allData.categories, { division_id: Number(value) })
                                                        setCategoryList(filteredCategory)
                                                        let filteredUnit = _.filter(allData && allData.units_list, { division_id: Number(value) })
                                                        setUnitLegalEntity(filteredUnit)
                                                    }}
                                                    placeholder="Enter Division"
                                                    style={{ width: '100%', marginTop: '2px' }} s
                                                    value={consolidateddata.division_name || undefined}
                                                    disabled={consolidateddata.domain == '' ? true : false}
                                                >
                                                    {divisionList && divisionList.length > 0 && divisionList.map((item, i) => {
                                                        return (
                                                            <Option key={item.division_id}>
                                                                {item.division_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <label htmlFor=""><b>Compliance Task : </b></label>
                                            <div className="form-group">
                                                <Input allowClear={true} placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '2px' }}

                                                    onChange={(e) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            compliance_task: e.target.value
                                                        })
                                                    }}
                                                    value={consolidateddata.compliance_task}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ marginTop: '5px' }}>
                                    <div className="row">

                                        <div className='col-md-4'>
                                            <label><b>Act : </b></label>
                                            <div className="form-group">
                                                <Select
                                                    allowClear={true}
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            act: value,
                                                            statutory_mapping: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Act"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                    value={consolidateddata.statutory_mapping || undefined}
                                                    disabled={consolidateddata.domain == '' ? true : false}
                                                >
                                                    {actLegalEntity && actLegalEntity.length > 0 && actLegalEntity.map((item, i) => {
                                                        return (
                                                            <Option key={item.parent_id}>
                                                                {item.statutory_mapping}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">

                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row text-center '>
                                        {/* <div className='col-md-4'></div>
                        <div className='col-md-4'></div> */}
                                        <div className='popupbtncolour' >
                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px" }}
                                                icon={<EyeOutlined />} onClick={onshow}
                                            >
                                                Show
                                            </Button>
                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal}
                                                icon={<ExportOutlined />}   >
                                                Export
                                            </Button>
                                        </div>
                                    </div>
                                </div></Modal>
                            {tableshow == true ?
                                <Card className='rounded-3'>
                                    <div className='col-md-12 mt-2 mb-2' >
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <label><b>Legal Entity :</b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? consolidateddata.legal_entity_name : localStorage.getItem('SelectedEntity')}</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <label><b>Country :</b> {sessionArr && sessionArr[0].c_name}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain :</b> {consolidateddata.domainName}</label>
                                            </div>


                                        </div>
                                    </div>
                                    <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content  userprivclass1'>
                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup>
                                                        <thead className="ant-table-thead antable-head" >
                                                            <tr>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '20px' }}>#</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '300px' }}>Compliance Task</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '30px' }}>Criticality</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}>Frequency</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '140px' }}>Admin Incharge</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}>Assignee</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}>Compliance Task Status</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '300px' }}><center>Penal Consequences</center></th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>History</center></th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '150px' }}><center>Uploaded Document</center></th>
                                                            </tr>
                                                        </thead>
                                                        {filteredArrayState && filteredArrayState.length ?
                                                            filteredArrayState.map((item, i) => {

                                                                return (
                                                                    <tbody className='ant-table-tbody' key={i}>
                                                                        <tr className="bg-lightdark">
                                                                            <td style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }} colSpan="100%">
                                                                                <div className='bg-white shadow-sm border-radius-reports'>
                                                                                    <div className="col-md-12">
                                                                                        <div className='row m-2'>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Vendor :</b> {item[0].unit_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Category :</b> {item[0].category_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Division :</b> {item[0].division_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        {titledata && titledata.map((itemdata, i) => {
                                                                            let data = _.find(itemdata.child, { unit_id: item[0].unit_id })
                                                                            if (data != undefined) {
                                                                                if (data.statutory_mapping == itemdata.parent) {
                                                                                    let list = itemdata.child
                                                                                    let size = 25
                                                                                    const items = list.slice(0, size)
                                                                                    return <Fragment>

                                                                                        <tr className='ant-table-row ant-table-row-level-0'>

                                                                                            <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                        </tr>

                                                                                        {itemdata.child.map((items) => {
                                                                                            if (item[0].unit_id == items.unit_id) {
                                                                                                console.log(items, 'items');
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                                            <td title={items.compliance_task} className='ant-table-cell'><Tooltip title={items.compliance_description}>
                                                                                                                <ExclamationCircleTwoTone />
                                                                                                            </Tooltip> &nbsp;{items.compliance_task}</td>
                                                                                                            <td className='ant-table-cell'><center>{items.Criticality}</center></td>
                                                                                                            <td className='ant-table-cell'>{items.frequency_name}</td>
                                                                                                            <td className='ant-table-cell'>{items.admin_incharge}</td>
                                                                                                            <td className='ant-table-cell'>{items.assignee_name}</td>
                                                                                                            <td className='ant-table-cell'>{items.task_status}</td>
                                                                                                            <td title={items.penal_consequences} className='ant-table-cell'>{items.penal_consequences}</td>
                                                                                                            <td className='ant-table-cell'><a href="javascript:;" onClick={() => {
                                                                                                                showTaskHistoryModal()
                                                                                                                let temp = []
                                                                                                                temp.push({
                                                                                                                    start_date: items.start_date,
                                                                                                                    due_date: items.due_date,
                                                                                                                    assignee: items.assignee_name,
                                                                                                                    concurr: items.concurrer_name,
                                                                                                                    approver: items.approver_name
                                                                                                                })
                                                                                                                setDataSource(temp)
                                                                                                            }}><center>View</center></a></td>
                                                                                                            <td className='ant-table-cell'><a href="javascript:;" onClick={() => {
                                                                                                                const payload =
                                                                                                                    [
                                                                                                                        authtoken,
                                                                                                                        {
                                                                                                                            "session_token": authtoken,
                                                                                                                            "request": [
                                                                                                                                "DownloadFile",
                                                                                                                                {
                                                                                                                                    "le_id": consolidateddata.legel_entity,
                                                                                                                                    "c_id": Number(consolidateddata.country),
                                                                                                                                    "d_id": Number(consolidateddata.domain),
                                                                                                                                    "u_id": items.unit_id,
                                                                                                                                    "start_date": items.start_date,
                                                                                                                                    "file_name": items.document_name
                                                                                                                                }
                                                                                                                            ]
                                                                                                                        }
                                                                                                                    ]

                                                                                                                downloadDocument({
                                                                                                                    payload: payload,
                                                                                                                    paramid: paramid
                                                                                                                })

                                                                                                            }}>{items.document_name}</a></td>
                                                                                                            {/* </tr> */}

                                                                                                        </tr>
                                                                                                        <span hidden>{index = index + 1}</span>
                                                                                                    </>
                                                                                                )
                                                                                            }

                                                                                        })

                                                                                        }
                                                                                    </Fragment>
                                                                                }
                                                                            }
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                )
                                                            }) :
                                                            <tr>
                                                                <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                            </tr>
                                                        }
                                                    </table>
                                                    {/* <br /> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row mt-3">
                                        {index != 0 ?
                                            <div className='col-md-6' >
                                                <label>Showing {pageSize * (current - 1) + 1} to {index} of {Number(count)} entries</label>
                                            </div> : ''}

                                        <div className='col-md-6 text-right'>
                                            <Pagination
                                                current={current}
                                                showSizeChanger={true}
                                                // showQuickJumper={true}
                                                onShowSizeChange={() => {
                                                    setCurrent(1)
                                                    // setpageState(true)
                                                }}
                                                pageSizeOptions={[25, 50, 100]} hideOnSinglePage={Number(count) > 25 ? false : true} defaultPageSize={25}
                                                onChange={(page, pageSizes) => {
                                                    setpageState(true)
                                                    setCurrent(pageSize !== pageSizes ? 1 : page);
                                                    setPageSize(pageSizes)
                                                }} total={Number(count)}
                                            // showTotal={(total) => `Showing 1 to ${index} of ${tableData.total_count} entries`}
                                            />
                                        </div>
                                    </div>

                                </Card>

                                // </div>
                                :
                                false
                            }


                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default connect(mapStateToProps, {
    RiskReportData,
    getRiskReportData,
    getRiskExportData,
    Downloadfile,
    downloadfiles,
    downloadDocument
})(RiskReport);