import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link, NavLink } from "react-router-dom";



const SideNav = () => {
  const history = useHistory();
  const callername = JSON.parse(localStorage.getItem('sessionValue'));
  const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
  const menu = Object.values(sessionValue.login_response.menu)
  //const menu = sessionValue.login_response.menu
  const [activeClass, setActiveClass] = useState('');
  console.log(activeClass, 'activeClass')
  const [sidebarbg, setBarBg] = useState('skin6')
  useEffect(() => {
    setBarBg(localStorage.getItem("currentSkin"))
  }, [localStorage.getItem("currentSkin")])
  return (
    <Fragment>
      <aside className="left-sidebar" data-sidebarbg={sidebarbg}>
        <div className="scroll-sidebar h-100">
          <nav className="sidebar-nav mt-3">
            <ul id="sidebarnav" className='px-1'>
              {/* <li className="sidebar-item">
                <Link to={'/home'}
                  className="sidebar-link has-arrow waves-effect waves-dark sidebarparent"
                  aria-expanded="false"
                ><i data-feather={'home'}></i>
                  <span className="hide-menu" >{'Home'}</span></Link>
                <Link to={'/dashboard'} id="to" className={"sidebar-link"}
                ><i className="ri-indeterminate-circle-line"></i>
                  <span className="hide-menu" style={{ width: "7rem" }}> Compliance Status</span></Link>
                  <Link to={'/dashboard'} id="to" className={"sidebar-link"}
                ><i className="ri-indeterminate-circle-line"></i>
                  <span className="hide-menu" style={{ width: "7rem" }}> Compliance Trend</span></Link>
                  <Link to={'/dashboard'} id="to" className={"sidebar-link"}
                ><i className="ri-indeterminate-circle-line"></i>
                  <span className="hide-menu" style={{ width: "7rem" }}> Comparative Trend</span></Link>
              </li> */}
              {menu.length > 0 && menu.map((menuList, c_s_Id) => {
                const arr = [];
                menuList.map(function (user) {
                  if ((arr.indexOf(user.parent_menu) < 0))
                    arr.push(user.parent_menu);
                });

                switch (menuList[0].form_type) {
                  case 'Dashboard':
                    var featherIcon = 'globe'
                    break;
                  case 'Master':
                    var featherIcon = 'grid'
                    break;
                  case 'Transaction':
                    var featherIcon = 'file-text'
                    break;
                  case 'Report':
                    var featherIcon = 'archive'
                    break;
                  default:
                    var featherIcon = 'list'
                    break;
                }
                if (menuList[0].form_type !== 'My Accounts' && menuList[0].form_type !== 'Static')
                  return <li className="sidebar-item" key={c_s_Id}>

                    <Link
                      className="sidebar-link px-1 py-1 has-arrow waves-effect waves-dark sidebarparent"
                      id={"c_s_Id" + c_s_Id}
                      aria-expanded="false"
                    ><i data-feather={featherIcon}></i>
                      <span className="hide-menu" >{menuList[0].form_type}</span></Link>
                    <ul className="collapse first-level subul">
                      {
                        arr.map((pmenu, pId) => {
                          // const subMenuListVar = (pmenu) ? " has-arrow waves-effect waves-dark" : '';
                          // const isChildTrue = (pmenu) ? "True" : "False";

                          if (pmenu) {
                            return <li className="sidebar-item" title={pmenu} key={pId}>
                              <Link className={"sidebar-link px-1 py-1 has-arrow waves-effect waves-dark sidebarparent"} id={"pId_" + pId}
                              ><i className="ri-add-circle-line"></i>
                                <span className="hide-menu fs-3" >{pmenu} </span></Link>
                              <ul className="collapse first-level subul">
                                {menuList && menuList.map((subMenuList, childIds) => {
                                  if (pmenu === subMenuList.parent_menu) {
                                    return <li className="sidebar-item" title={subMenuList.form_name} key={childIds}>
                                      {activeClass == "subchild_" + childIds + "_" + subMenuList.form_id ?
                                        <Link to={subMenuList.form_url} style={{ pointerEvents: 'none' }} id={"subchild_" + childIds + "_" + subMenuList.form_id} className={"sidebar-link px-1 py-1 active"}
                                        >
                                          <i className="ri-indeterminate-circle-line"></i>
                                          <span className="hide-menu fs-3" >{subMenuList.form_name} </span></Link>
                                        :
                                        <Link to={subMenuList.form_url} id={"subchild_" + childIds + "_" + subMenuList.form_id} className={"sidebar-link px-1 py-1"}
                                          onClick={(e) => {
                                            localStorage.setItem('formkey', subMenuList.form_key)
                                            setActiveClass("subchild_" + childIds + "_" + subMenuList.form_id)
                                          }}
                                        ><i className="ri-indeterminate-circle-line"></i>
                                          <span className="hide-menu fs-3" >{subMenuList.form_name} </span></Link>}
                                    </li>
                                  }
                                })
                                }
                              </ul>
                            </li>

                          }
                          else if (menuList[0].form_type == 'Dashboard') {
                            return menuList.length > 0 && menuList.map((subMenuList) => {
                              if (subMenuList.parent_menu == null) {
                                return (
                                  <>
                                  <li className="sidebar-item" title="Chart">
                                      <a href='/page' className={"sidebar-link px-1 py-1"}
                                      ><i className="ri-indeterminate-circle-line"></i>
                                        <span className="hide-menu fs-3">Landing Page</span></a>
                                    </li>
                                    <li className="sidebar-item" title="Chart">
                                      <Link to={`${subMenuList.form_url}/compliance`} id="to" className={"sidebar-link px-1 py-1"}
                                      ><i className="ri-indeterminate-circle-line"></i>
                                        <span className="hide-menu fs-3"> Compliance Status</span></Link>
                                    </li>
                                    <li className="sidebar-item" title="Chart">
                                      <Link to={`${subMenuList.form_url}/complianceTrend`} id="to" className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                        localStorage.setItem('formkey', subMenuList.form_key)
                                      }}
                                      ><i className="ri-indeterminate-circle-line"></i>
                                        <span className="hide-menu fs-3"> Compliance Trend</span></Link>
                                    </li>
                                    <li className="sidebar-item" title="Chart">
                                      <Link to={`${subMenuList.form_url}/comparative`} id="to" className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                        localStorage.setItem('formkey', subMenuList.form_key)
                                      }}
                                      ><i className="ri-indeterminate-circle-line"></i>
                                        <span className="hide-menu fs-3"> Comparative Trend</span></Link>
                                    </li>
                                    <li className="sidebar-item" title="Chart">
                                      <Link to={`${subMenuList.form_url}/risk`} id="to" className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                        localStorage.setItem('formkey', subMenuList.form_key)
                                      }}
                                      ><i className="ri-indeterminate-circle-line"></i>
                                        <span className="hide-menu fs-3"> Risk Chart</span></Link>
                                    </li>
                                    <li className="sidebar-item" title="Criticality Chart">
                                      <Link to={`${subMenuList.form_url}/criticality`} className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                        localStorage.setItem('formkey', subMenuList.form_key)
                                        localStorage.setItem('type', "criticality")
                                      }}
                                      ><i className="ri-indeterminate-circle-line"></i>
                                        <span className="hide-menu fs-3">Criticality Chart</span></Link>
                                    </li>
                                    <li className="sidebar-item" title="Chart">
                                      <Link to={`${subMenuList.form_url}/taskDistribution`} id="to" className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                        localStorage.setItem('formkey', subMenuList.form_key)
                                      }}
                                      ><i className="ri-indeterminate-circle-line"></i>
                                        <span className="hide-menu fs-3">Task Distribution</span></Link>
                                    </li>
                                    <li className="sidebar-item" title="Chart">
                                      <Link to={`${subMenuList.form_url}/overdue`} id="to" className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                        localStorage.setItem('formkey', subMenuList.form_key)
                                      }}
                                      ><i className="ri-indeterminate-circle-line"></i>
                                        <span className="hide-menu fs-3"> Over Due Chart</span></Link>
                                    </li>
                                    <li className="sidebar-item" title="Chart">
                                      <Link to={`${subMenuList.form_url}/assigneewisecompliance`} id="to" className={"sidebar-link px-1 py-1"} onClick={(e) => {
                                        localStorage.setItem('formkey', subMenuList.form_key)
                                      }}
                                      ><i className="ri-indeterminate-circle-line"></i>
                                        <span className="hide-menu fs-3"> Assignee Wise Compliance</span></Link>
                                    </li>
                                    {/* <li className="sidebar-item" title="Compliance Trend">
                                      <Link to={subMenuList.form_url} id="to" className={"sidebar-link"} onClick={(e) => {
                                        localStorage.setItem('formkey', subMenuList.form_key)
                                      }}
                                      ><i className="ri-indeterminate-circle-line"></i>
                                        <span className="hide-menu" style={{ width: "7rem" }}> Compliance Trend</span></Link>
                                    </li> */}
                                  </>
                                )
                              }
                            })
                          }
                          else {
                            return menuList.length > 0 && menuList.map((subMenuList, childId) => {
                              if (subMenuList.parent_menu == null) {
                                return (<li className="sidebar-item" title={subMenuList.form_name}>
                                  {activeClass == "child_" + childId + "_" + subMenuList.form_id ?
                                    <NavLink to={subMenuList.form_url} style={{ pointerEvents: 'none' }} id={"child_" + childId + "_" + subMenuList.form_id} activeClassName="active" className={"sidebar-link px-1 py-1"}
                                    >
                                      <i className="ri-indeterminate-circle-line"></i>
                                      <span className="hide-menu fs-3">{subMenuList.form_name}</span></NavLink>
                                    : <NavLink to={subMenuList.form_url} id={"child_" + childId + "_" + subMenuList.form_id} className={"sidebar-link px-1 py-1"}
                                      onClick={(e) => {
                                        localStorage.setItem('formkey', subMenuList.form_key)
                                        setActiveClass("child_" + childId + "_" + subMenuList.form_id)
                                      }}
                                    ><i className="ri-indeterminate-circle-line"></i>
                                      <span className="hide-menu fs-3">{subMenuList.form_name}</span></NavLink>}
                                </li>)
                              }
                            })
                          }
                        })
                      }

                    </ul>
                  </li>
              })}
            </ul>
          </nav>
        </div>
      </aside>

    </Fragment>
  )
};
export default connect(null)(SideNav);